import { Typography } from "@mui/material";
import { City, Professional, Team } from "@syadem/ariane-js";
import { isEqual } from "lodash-es";
import { JSX, useState } from "react";
import { useCitiesQuery } from "../../hooks/useCitiesQuery";
import { useI18n } from "../../hooks/useI18n";
import { StyledAutocomplete } from "../mui/StyledAutoComplete";
import { useCountryConfig } from "../../hooks/useCountryConfig";

export const CitySearchField = ({
  city,
  splitZipCode,
  required = false,
  cityError,
  onCityChange,
  professional,
  onProfessionalChange,
  onArianeError,
}: {
  city: City | undefined | null;
  splitZipCode?: boolean;
  required?: boolean;
  cityError?: boolean;
  onCityChange: (city?: City) => void;
  professional?: Professional | Team;
  onProfessionalChange?: (professional?: Professional | Team) => void;
  onArianeError?: (error: unknown) => void;
}): JSX.Element => {
  const [cityInput, setCityInput] = useState<string | undefined>(undefined);
  const [isLoading, cities] = useCitiesQuery({ search: cityInput, splitZipCode, onArianeError });
  const { t } = useI18n();
  const { zipCode } = useCountryConfig();

  return (
    <StyledAutocomplete
      required={required}
      fullWidth
      options={cities}
      label={t("common.user_infos.city")}
      placeholder={zipCode.sharingPlaceholder}
      filterOptions={(x) => x}
      loading={isLoading}
      testId="city"
      error={cityError || undefined}
      getOptionLabel={(city) => (city.department ? `${city.name} (${city.department})` : city.name)}
      renderOption={(props, city, state) => (
        <li data-testid="cityOption" {...props} key={`${city.name}-${state.index}`}>
          {renderCity(city)}
        </li>
      )}
      value={city ?? null}
      onInputChange={(_event, newCityInput) => {
        setCityInput(newCityInput ?? undefined);
      }}
      onChange={(_event, newCity) => {
        if (onCityChange) onCityChange(newCity ?? undefined);
        if (!newCity) {
          setCityInput(undefined);
        }

        // Reset professional on city change
        if (professional && onProfessionalChange) {
          onProfessionalChange(undefined);
        }
      }}
      isOptionEqualToValue={isEqual}
    />
  );
};

function renderCity(city: City) {
  return (
    <Typography fontSize="0.8rem">
      {city.department ? `${city.name} ${city.zip_code.join(", ")} (${city.department})` : city.name}
    </Typography>
  );
}
