import { useContext } from "react";
import { User } from "../../domain/user";
import { AppState } from "../../store";
import { useStoreWithEqualityFn } from "zustand/traditional";
import { DependenciesContext } from "../providers/DependenciesProvider";

export function useAuthenticatedUser(): User {
  const user = useContextStore(userSelector);
  if (!user) throw new Error("Unexpected null user");
  return user;
}

export function useAuthState(): AppState["authState"] {
  return useContextStore(authStateSelector);
}

function useContextStore<T>(selector: (state: AppState) => T): T {
  const deps = useContext(DependenciesContext);
  if (!deps) {
    throw new Error("No dependencies container, did you forget to pass it to the provider?");
  } else {
    return useStoreWithEqualityFn(deps.store, selector);
  }
}

const authStateSelector = (state: AppState) => state.authState;
const userSelector = (state: AppState) => (state.authState.type === "signed-in" ? state.authState.user : null);
