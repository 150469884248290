import { VaccinationActInjectionLocationEnum, VaccinationActInjectionMethodEnum } from "@syadem/kairos-citizen-js";
import { dayjs } from "../utils/dayjs";
import yup from "../utils/yup";

export const getVaccinationSchema = (birthDate?: Date) => {
  return yup.object({
    performedOn: yup
      .date()
      .min(
        dayjs
          .utc(birthDate || "1900-01-01")
          .startOf("day")
          .toDate(),
      )
      .max(dayjs().endOf("day").utc().toDate())
      .required(),
    vaccineId: yup.string().required(),
    expirationDate: yup.date().min(dayjs.utc("1900-01-01").startOf("day").toDate()).nullable(),
    batchNumber: yup.string(),
    serialNumber: yup.string(),
    booster: yup.boolean(),
    injectionLocation: yup
      .mixed<VaccinationActInjectionLocationEnum>()
      .oneOf(Object.values(VaccinationActInjectionLocationEnum)),
    injectionMethod: yup
      .mixed<VaccinationActInjectionMethodEnum>()
      .oneOf(Object.values(VaccinationActInjectionMethodEnum)),
    countryCode: yup.string().required(),
  });
};

export const getSimplifiedVaccinationsSchema = (birthDate?: Date) => {
  return yup.object({
    performedOn: yup
      .date()
      .min(
        dayjs
          .utc(birthDate || "1900-01-01")
          .startOf("day")
          .toDate(),
      )
      .max(dayjs().endOf("day").utc().toDate())
      .required(),
    vaccineId: yup.string().required(),
    booster: yup.boolean(),
    countryCode: yup.string().required(),
  });
};
