import CheckIcon from "@mui/icons-material/Check";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  AccordionDetails,
  AccordionSummary,
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid2,
  Input,
  LinearProgress,
  MenuItem,
  Paper,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import {
  ModelError,
  ResponseError,
  VaccinationAct,
  VaccinationActCertificatorTypeEnum,
  VaccinationActInjectionLocationEnum,
  VaccinationActInjectionMethodEnum,
} from "@syadem/kairos-citizen-js";
import { Formik, useFormikContext } from "formik";
import Polyglot from "node-polyglot";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Asserts } from "yup";
import { Country } from "../../../domain/country";
import { certificationDescription } from "../../../utils/certificationDescription";
import { formatDateForApi } from "../../../utils/date";
import { dayjs } from "../../../utils/dayjs";
import { createTranslateFn, TranslateFunction } from "../../../utils/formUtils";
import { compareNormalizedString } from "../../../utils/string";
import yup from "../../../utils/yup";
import AutoCloseAlert from "../../components/AutoCloseAlert";
import DatamatrixButton from "../../components/Datamatrix";
import FormVaccinesAutocomplete from "../../components/FormVaccinesAutocomplete";
import { isCertified } from "../../components/VaccinationActListItem";
import { StyledAccordion } from "../../components/mui/StyledAccordion";
import { StyledAutocomplete } from "../../components/mui/StyledAutoComplete";
import { CancelButton, CancelLinkButton } from "../../components/mui/StyledButtons";
import { StyledCheckbox } from "../../components/mui/StyledCheckbox";
import StyledDatepicker from "../../components/mui/StyledDatepicker";
import { StyledInput } from "../../components/mui/StyledInput";
import { StyledSelect } from "../../components/mui/StyledSelect";
import { ListItemDeleteButton } from "../../components/shared/ListItemDeleteButton";
import { useArianeApi } from "../../hooks/useArianeApi";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useHealthRecordVaccination } from "../../hooks/useHealthRecordVaccination";
import { useI18n } from "../../hooks/useI18n";
import { useKairosApi } from "../../hooks/useKairosApi";
import { useVaccineWithDiseases } from "../../hooks/useVaccineWithDiseases";
import { useVaccinesWithDiseases } from "../../hooks/useVaccinesWithDiseases";
import { theme } from "../../layout/Theme";
import AddSameVaccineDialog from "./AddSameVaccineDialog";
import { useAuthenticatedUser } from "../../hooks";
import { useCountryConfig } from "../../hooks/useCountryConfig";

export const getVaccinationSchema = (birthDate?: Date) => {
  return yup.object({
    performedOn: yup
      .date()
      .min(
        dayjs
          .utc(birthDate || "1900-01-01")
          .startOf("day")
          .toDate(),
      )
      .max(dayjs().endOf("day").utc().toDate())
      .required(),
    vaccineId: yup.string().required(),
    expirationDate: yup.date().min(dayjs.utc("1900-01-01").startOf("day").toDate()).nullable(),
    batchNumber: yup.string(),
    serialNumber: yup.string(),
    booster: yup.boolean(),
    injectionLocation: yup
      .mixed<VaccinationActInjectionLocationEnum>()
      .oneOf(Object.values(VaccinationActInjectionLocationEnum)),
    injectionMethod: yup
      .mixed<VaccinationActInjectionMethodEnum>()
      .oneOf(Object.values(VaccinationActInjectionMethodEnum)),
    countryCode: yup.string().required(),
  });
};

export function AddVaccinationSimplified({
  isEmbedded = false,
  refreshCallback,
  setSnackOpen,
  handleClose,
  isDatamatrixOpen,
}: {
  isEmbedded?: boolean;
  refreshCallback?: () => void;
  setSnackOpen?: (value: React.SetStateAction<boolean>) => void;
  handleClose?: () => void;
  isDatamatrixOpen?: boolean;
}) {
  const { id: healthRecordId, vaccinationActId } = useParams() as {
    id: string;
    vaccinationActId: string;
  };

  const { t, locale, getObject } = useI18n();
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);
  const { healthRecord, isLoading: healthRecordIsLoading, refetch } = useHealthRecord(healthRecordId);
  const { vaccinationAct, isLoading } = useHealthRecordVaccination(healthRecordId, vaccinationActId);
  const { isLoading: isLoadingVaccinesWithDiseases } = useVaccinesWithDiseases();
  const [isReadonly, setIsReadonly] = useState<boolean>(false);
  const [countryCodeInput, setCountryCodeInput] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const [certificator, setCertificator] = useState<(Professional | Team) | undefined>(undefined);
  const [openSameVaccinationDialog, setOpenSameVaccinationDialog] = useState(false);
  const [alertIsOpen, setAlertIsOpen] = useState(false);
  // Submission
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const api = useKairosApi();

  const arianeApi = useArianeApi();
  const currentUser = useAuthenticatedUser();
  const { defaultCountryCode } = useCountryConfig();
  const [showAdditionalInfos, setShowAdditionalInfos] = useState(false);

  useEffect(() => {
    if (vaccinationAct) {
      setIsReadonly(isCertified(vaccinationAct.certificationMethod));
      setShowAdditionalInfos(true);
    }
  }, [vaccinationAct, currentUser]);

  useEffect(() => {
    (async () => {
      if (vaccinationAct) {
        let actCertificators: (Professional | Team)[] = [];

        if (vaccinationAct.certificator) {
          try {
            actCertificators = await arianeApi.professionals.searchByIds([vaccinationAct.certificator.id]);
          } catch (_error) {
            actCertificators = [];
          }
        }

        setCertificator(actCertificators[0]);
      }
    })();
  }, [arianeApi?.professionals, vaccinationAct]);

  const countries = useMemo(() => {
    return Object.entries(getObject("countries")).reduce(
      (acc: Country[], [key, value]) => [...acc, { code: key, name: value }],
      [],
    );
  }, [getObject]);

  return (
    <>
      {isLoading && isLoadingVaccinesWithDiseases && healthRecordIsLoading && !vaccinationAct && (
        <LinearProgress variant="query" />
      )}
      {!isLoading && !isLoadingVaccinesWithDiseases && (!vaccinationActId || vaccinationAct) && (
        <Paper
          sx={{
            overflow: "hidden",
            border: isEmbedded ? "none" : `solid 1px ${theme.palette.neutral[200]}`,
            px: 4,
            py: 2,
          }}
          elevation={0}
        >
          <Formik
            initialValues={{
              vaccineId: vaccinationAct?.vaccineId || "",
              countryCode: vaccinationAct?.countryCode || defaultCountryCode,
              performedOn: vaccinationAct?.performedOn
                ? dayjs.utc(vaccinationAct?.performedOn).toDate()
                : dayjs().startOf("day").utc().toDate(),
              expirationDate: vaccinationAct?.expirationDate
                ? dayjs.utc(vaccinationAct?.expirationDate).toDate()
                : undefined,
              batchNumber: vaccinationAct?.batchNumber || "",
              serialNumber: vaccinationAct?.serialNumber || "",
              injectionLocation: vaccinationAct?.injectionLocation || "",
              injectionMethod: vaccinationAct?.injectionMethod || "",
              booster: vaccinationAct?.booster || false,
            }}
            validationSchema={getVaccinationSchema(healthRecord?.birthDate)}
            onSubmit={async (values, { setSubmitting }) => {
              if (api) {
                setErrorMessage(undefined);
                try {
                  if (vaccinationActId) {
                    await api.vaccinationActApi.updateVaccinationAct(healthRecordId, vaccinationActId, {
                      vaccinationAct: {
                        ...values,
                        ...(values.expirationDate ? { expirationDate: formatDateForApi(values.expirationDate) } : {}),
                        performedOn: formatDateForApi(values.performedOn),
                      },
                    });
                    navigate(`..?updated=true`);
                    if (setSnackOpen) setSnackOpen(true);
                  } else {
                    const vaccinationActs = healthRecord?.vaccinationActs || [];
                    const vaccinationAct = vaccinationActs.find((vaccinationAct) => {
                      return (
                        dayjs(vaccinationAct.performedOn).isSame(values.performedOn, "day") &&
                        vaccinationAct.vaccineId === values.vaccineId
                      );
                    });

                    if (vaccinationAct) {
                      setOpenSameVaccinationDialog(true);
                    } else {
                      await api.vaccinationActApi.createVaccinationAct(healthRecordId, {
                        vaccinationAct: {
                          ...values,
                          ...(values.expirationDate ? { expirationDate: formatDateForApi(values.expirationDate) } : {}),
                          performedOn: formatDateForApi(values.performedOn),
                        },
                      });
                      if (!isEmbedded) {
                        navigate(`..?updated=true`);
                      } else {
                        refetch();
                        if (refreshCallback) refreshCallback();
                        setAlertIsOpen(true);
                      }
                    }
                  }
                } catch (e) {
                  setSubmitting(false);
                  if (
                    (e instanceof Response && e.status === 400) ||
                    (e instanceof ResponseError && e.response.status === 400)
                  ) {
                    const response = e instanceof Response ? e : e.response;
                    const code = ((await response.json()) as ModelError).code;

                    switch (code) {
                      case "act_cannot_be_performed_in_the_future_or_before_patient_birth_date":
                        setErrorMessage(t("vaccines.error_messages.error_1"));
                        break;
                      case "act_cannot_be_performed_after_vaccine_expiration_date":
                        setErrorMessage(t("vaccines.error_messages.error_2"));
                        break;
                      case "incorrect_injection_method":
                        setErrorMessage(t("vaccines.error_messages.error_3"));
                        break;
                      case "incorrect_injection_location":
                        setErrorMessage(t("vaccines.error_messages.error_4"));
                        break;
                    }
                  } else {
                    setErrorMessage(t("common.alerts.alert_notification"));
                  }
                }
              }
            }}
          >
            {({
              values,
              errors,
              getFieldProps,
              touched,
              handleSubmit,
              setFieldValue,
              setFieldTouched,
              isSubmitting,
            }) => (
              <>
                <form onSubmit={handleSubmit} noValidate autoComplete="off">
                  <Input
                    type="text"
                    id="PreventChromeAutocomplete"
                    name="PreventChromeAutocomplete"
                    autoComplete="address-level4"
                    sx={{ display: "none" }}
                  />
                  <Grid2 container rowSpacing={2} columnSpacing={3} justifyContent="space-between">
                    <Grid2 size={12} container justifyContent="space-between">
                      <Grid2>
                        <Typography variant="h6" marginBottom={1}>
                          {vaccinationAct
                            ? isReadonly
                              ? t("vaccines.view_vaccination")
                              : t("vaccines.edit_vaccination")
                            : t("vaccines.add_vaccination")}
                        </Typography>
                      </Grid2>
                      {!isReadonly ? (
                        <Grid2>
                          <DatamatrixButton isOpen={isDatamatrixOpen} />
                        </Grid2>
                      ) : null}
                    </Grid2>
                    {errorMessage ? (
                      <Grid2 size={12}>
                        <Alert severity="error">{errorMessage}</Alert>
                      </Grid2>
                    ) : null}
                    <Grid2 container size={12} columnSpacing={2} rowSpacing={2}>
                      <Grid2 size={{ xs: 12, md: 6, lg: 4 }}>
                        <StyledDatepicker
                          {...getFieldProps("performedOn")}
                          placeholder={t("common.dates.datePlaceholder")}
                          label={t("vaccines.date_act")}
                          testId="performedOn"
                          required
                          fullWidth
                          readOnly={isReadonly}
                          minDate={dayjs(healthRecord?.birthDate)}
                          maxDate={dayjs()}
                          onChange={(date) => {
                            setFieldTouched("performedOn", true, false);
                            setFieldValue("performedOn", date ? date.toDate() : "");
                          }}
                          error={touched.performedOn && !!errors.performedOn}
                          errorMessage={errors.performedOn}
                          translateErrors={translateErrors}
                          touched={!!touched.performedOn}
                          warningText={
                            values.performedOn &&
                            values.expirationDate &&
                            dayjs(values.performedOn).isValid() &&
                            dayjs(values.expirationDate).isValid() &&
                            dayjs(values.performedOn).isAfter(dayjs(values.expirationDate))
                              ? t("vaccines.act_date_greater_than_expiration_date")
                              : undefined
                          }
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6, lg: 8 }}>
                        <FormVaccinesAutocomplete readOnly={isReadonly} />
                        <Booster
                          isReadonly={isReadonly}
                          vaccinationDate={values.performedOn}
                          birthDate={healthRecord?.birthDate}
                        />
                      </Grid2>
                    </Grid2>
                    <Grid2 size={12}>
                      <StyledAccordion
                        expanded={showAdditionalInfos}
                        onChange={() => setShowAdditionalInfos((prevState) => !prevState)}
                      >
                        <AccordionSummary
                          id="panel-header"
                          aria-controls="panel-content"
                          expandIcon={<ExpandMoreIcon />}
                        >
                          <Typography
                            sx={{
                              color: theme.palette.neutral[600],
                              fontSize: 14,
                              fontWeight: 600,
                              fontFamily: ["Open Sans", "sans-serif"].join(","),
                            }}
                          >
                            {t("vaccines.additional_information")}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Grid2 size={12} container columnSpacing={3} rowSpacing={2}>
                            <Grid2 size={12} container columnSpacing={3} rowSpacing={2}>
                              <Grid2 size={{ xs: 12, lg: 6 }}>
                                <StyledInput
                                  id="batchInput"
                                  type="text"
                                  {...getFieldProps("batchNumber")}
                                  label={t("vaccines.batch_number")}
                                  readOnly={isReadonly}
                                  fullWidth
                                  placeholder="ex : 3455673452372737"
                                  autoComplete="cc-csc" // hack to not have credit card number validation message from Chrome because the label contains the word "Number"
                                />
                              </Grid2>
                              <Grid2 size={{ xs: 12, lg: 6 }} sx={{ visibility: "hidden" }}>
                                <StyledInput
                                  id="serialInput"
                                  {...getFieldProps("serialNumber")}
                                  label={t("vaccines.serial_number")}
                                  readOnly={isReadonly}
                                  fullWidth
                                  placeholder="ex : 27482783002389"
                                  autoComplete="cc-csc" // hack to not have credit card number validation message from Chrome because the label contains the word "Number"
                                />
                              </Grid2>
                            </Grid2>
                            <Grid2 size={12} container columnSpacing={3} rowSpacing={2}>
                              <Grid2 size={{ xs: 12, lg: 6 }}>
                                <StyledSelect
                                  {...getFieldProps("injectionMethod")}
                                  label={t("vaccines.injection_path")}
                                  fullWidth
                                  placeholder={`ex : ${t("injectionMethods.intramuscular")}`}
                                  renderValue={(injectionMethod) => t(`injectionMethods.${injectionMethod}`)}
                                  readOnly={isReadonly}
                                >
                                  {Object.values(VaccinationActInjectionMethodEnum).map((filteredInjectionMethod) => (
                                    <MenuItem key={filteredInjectionMethod} value={filteredInjectionMethod}>
                                      {t(`injectionMethods.${filteredInjectionMethod}`)}
                                    </MenuItem>
                                  ))}
                                </StyledSelect>
                              </Grid2>
                              <Grid2 size={{ xs: 12, lg: 6 }}>
                                <StyledSelect
                                  {...getFieldProps("injectionLocation")}
                                  label={t("vaccines.injection_location")}
                                  fullWidth
                                  placeholder={`ex : ${t("injectionLocations.left_deltoid")}`}
                                  renderValue={(injectionLocation) => t(`injectionLocations.${injectionLocation}`)}
                                  readOnly={isReadonly}
                                >
                                  {Object.values(VaccinationActInjectionLocationEnum).map(
                                    (filteredInjectionLocation) => (
                                      <MenuItem key={filteredInjectionLocation} value={filteredInjectionLocation}>
                                        {t(`injectionLocations.${filteredInjectionLocation}`)}
                                      </MenuItem>
                                    ),
                                  )}
                                </StyledSelect>
                              </Grid2>
                            </Grid2>
                            <Grid2 size={{ xs: 12, lg: 6 }}>
                              <StyledDatepicker
                                {...getFieldProps("expirationDate")}
                                placeholder={t("common.dates.datePlaceholder")}
                                label={t("vaccines.expiration_date")}
                                onChange={(date) => {
                                  setFieldTouched("expirationDate", true);
                                  setFieldValue("expirationDate", date ? date.toDate() : null);
                                }}
                                testId="expirationDate"
                                readOnly={isReadonly}
                                fullWidth
                                error={touched.expirationDate && !!errors.expirationDate}
                                errorMessage={errors.expirationDate}
                                translateErrors={translateErrors}
                                touched={!!touched.expirationDate}
                              />
                            </Grid2>
                            <Grid2 size={{ xs: 12, lg: 6 }}>
                              <StyledAutocomplete
                                id="countryCode"
                                label={t("common.user_infos.country")}
                                error={touched.countryCode && !!errors.countryCode}
                                errorMessage={errors.countryCode}
                                touched={touched.countryCode}
                                translateErrors={translateErrors}
                                fullWidth
                                placeholder="ex : France"
                                required
                                readOnly={isReadonly}
                                options={countries}
                                filterOptions={(x) =>
                                  x.filter((e) => compareNormalizedString(e.name, countryCodeInput || ""))
                                }
                                getOptionLabel={(country: Country) => country.name || ""}
                                onOpen={() => setFieldTouched("countryCode", true)}
                                value={
                                  countries.find(
                                    (country) => country.code == (values.countryCode ?? vaccinationAct?.countryCode),
                                  ) || null
                                }
                                onInputChange={(_event, newCountryCode, reason) => {
                                  if (reason === "input") {
                                    setCountryCodeInput(newCountryCode);
                                  }
                                }}
                                onChange={(_event, newCountryCode) => {
                                  setFieldTouched("countryCode", true);
                                  setFieldValue("countryCode", newCountryCode?.code);
                                  if (!newCountryCode) {
                                    setCountryCodeInput(undefined);
                                    setFieldValue("countryCode", "");
                                  }
                                }}
                              />
                            </Grid2>
                          </Grid2>
                        </AccordionDetails>
                      </StyledAccordion>
                    </Grid2>
                    {vaccinationAct && isReadonly ? (
                      <Grid2 size={12}>
                        {
                          <>
                            <Typography variant="body2">
                              {certificationDescription(certificator, vaccinationAct, t, locale)}
                            </Typography>
                            <Typography sx={{ fontSize: "0.8rem", fontStyle: "italic", color: "#808080" }}>
                              {certificationAdditionalInfo(vaccinationAct, t)}
                            </Typography>
                          </>
                        }
                      </Grid2>
                    ) : null}
                    <Grid2 size={12} container justifyContent="space-between" alignItems="center" marginTop="30px">
                      <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                        {isEmbedded ? (
                          <CancelButton onClick={handleClose} sx={{ mr: 2 }}>
                            {t("common.cta.close")}
                          </CancelButton>
                        ) : (
                          <CancelLinkButton
                            component={Link}
                            to={`${pathname.substring(
                              0,
                              pathname.lastIndexOf("/health-records") + 15,
                            )}/${healthRecordId}`}
                            variant="contained"
                            disableElevation
                          >
                            {t("common.cta.cancel")}
                          </CancelLinkButton>
                        )}
                        {!isReadonly && api && vaccinationAct ? (
                          <ListItemDeleteButton
                            deleteRecord={() =>
                              api.vaccinationActApi.deleteVaccinationAct(
                                vaccinationAct.healthRecordId as string,
                                vaccinationAct.id,
                              )
                            }
                            refreshCallback={() => {
                              navigate(
                                `${pathname.substring(
                                  0,
                                  pathname.lastIndexOf("/health-records") + 15,
                                )}/${healthRecordId}`,
                              );
                            }}
                            title={t("common.cta.delete")}
                            modalTitle={t("vaccines.delete_vaccination")}
                            modalContent={t("vaccines.delete_vaccination_validation")}
                            icon={<DeleteOutlineIcon />}
                            sx={{ ml: 1 }}
                            variant="outlined"
                            buttonWidth="110px"
                          />
                        ) : null}
                        <AutoCloseAlert
                          icon={<CheckIcon fontSize="inherit" />}
                          severity="success"
                          open={alertIsOpen}
                          handleClose={() => setAlertIsOpen(false)}
                        >
                          {t("vaccines.addVaccinationConfirmed")}
                        </AutoCloseAlert>
                      </Box>
                      {!isReadonly ? (
                        <LoadingButton
                          variant="contained"
                          disableElevation
                          type="submit"
                          loading={isSubmitting}
                          data-testid="submit"
                        >
                          {vaccinationAct ? t("common.cta.save") : t("common.cta.add")}
                        </LoadingButton>
                      ) : null}
                    </Grid2>
                  </Grid2>
                </form>
                <AddSameVaccinationDialog
                  healthRecordId={healthRecordId}
                  refreshCallback={refreshCallback}
                  open={openSameVaccinationDialog}
                  onClose={() => setOpenSameVaccinationDialog(false)}
                  openAlert={() => setAlertIsOpen(true)}
                />
              </>
            )}
          </Formik>
        </Paper>
      )}
    </>
  );
}

export const Booster = ({
  isReadonly = false,
  birthDate,
  vaccinationDate,
  sx,
}: {
  isReadonly?: boolean;
  birthDate: Date | undefined;
  vaccinationDate: Date | undefined;
  sx?: SxProps<Theme> | undefined;
}) => {
  const _vaccinationSchema = getVaccinationSchema();
  const { values, handleChange, setFieldValue } = useFormikContext<Asserts<typeof _vaccinationSchema>>();
  const { t } = useI18n();
  const [isVisible, setIsVisible] = useState(values?.booster !== undefined ? values?.booster : false);
  const vaccineWithDiseases = useVaccineWithDiseases(values?.vaccineId);

  useEffect(() => {
    if (vaccinationDate) {
      if (vaccineWithDiseases) {
        //Display booster if age is more or equal than 5
        const isMoreOrEqualFive = vaccinationDate && dayjs(dayjs(vaccinationDate)).diff(birthDate, "year") >= 5;
        //And if disease equal to Diphteria or Tetanos or Polio
        const specificDiseases = ["Diphtérie", "Tétanos", "Poliomyélite"];
        const isDiphteriaOrTetanosOrPolio = vaccineWithDiseases?.diseases.some((disease) =>
          specificDiseases.includes(disease.name["fr"] as string),
        );
        if (isMoreOrEqualFive && isDiphteriaOrTetanosOrPolio) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setFieldValue("booster", false);
        }
      }
    }
  }, [vaccineWithDiseases, vaccinationDate, birthDate, setFieldValue]);

  return isVisible ? (
    <Box sx={{ mt: 0.5, ...sx }}>
      <StyledCheckbox fullWidth disabled={isReadonly} testId="booster">
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              checked={values?.booster}
              name="booster"
              value="yes"
              size="small"
              data-testid="boosterCheckbox"
            />
          }
          label={<Typography variant="body2">{t("vaccines.booster")}</Typography>}
        />
      </StyledCheckbox>
    </Box>
  ) : null;
};

export function certificationAdditionalInfo(
  vaccinationAct: VaccinationAct,
  t: (phrase: string, options?: number | Polyglot.InterpolationOptions | undefined) => string,
): string {
  if (vaccinationAct.certificator?.type == VaccinationActCertificatorTypeEnum.Professional) {
    return t("vaccines.editableByProOnly");
  } else if (vaccinationAct.certificator?.type == VaccinationActCertificatorTypeEnum.Team) {
    return t("vaccines.editableByTeamOnly");
  } else {
    return "";
  }
}

const AddSameVaccinationDialog = ({
  healthRecordId,
  open,
  onClose,
  openAlert,
  refreshCallback,
}: {
  healthRecordId: string;
  open: boolean;
  onClose: () => void;
  openAlert: () => void;
  refreshCallback?: () => void;
}) => {
  const _vaccinationSchema = getVaccinationSchema();
  const { values } = useFormikContext<Asserts<typeof _vaccinationSchema>>();
  const api = useKairosApi();
  const [isCreating, setIsCreating] = useState(false);

  const createVaccine = async () => {
    setIsCreating(true);
    const result = await api?.vaccinationActApi.createVaccinationAct(healthRecordId, {
      vaccinationAct: {
        ...values,
        ...(values.expirationDate ? { expirationDate: formatDateForApi(values.expirationDate) } : {}),
        performedOn: formatDateForApi(values.performedOn),
      },
    });

    if (result) {
      if (refreshCallback) refreshCallback();
      setIsCreating(false);
      onClose();
      openAlert();
    }
  };

  return <AddSameVaccineDialog open={open} onClose={onClose} createVaccine={createVaccine} isCreating={isCreating} />;
};
