import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ShareIcon from "@mui/icons-material/Share";
import { LoadingButton } from "@mui/lab";
import { Alert, AlertTitle, Box, Button, Checkbox, FormControlLabel, Grid2, Link, Typography } from "@mui/material";
import { City, Professional, Team } from "@syadem/ariane-js";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useI18n } from "../../hooks/useI18n";
import { useKairosApi } from "../../hooks/useKairosApi";
import { theme } from "../../layout/Theme";
import { StyledCheckbox } from "../mui/StyledCheckbox";
import { CityAndProAutocomplete } from "./CityAndProAutocomplete";
import { ProCard } from "./ProCard";
import { TeamCard } from "./TeamCard";

export function ShareWithProForm({ onSubmitSuccessful }: { onSubmitSuccessful: () => void }) {
  const { t } = useI18n();
  const api = useKairosApi();
  const { id: healthRecordId } = useParams() as { id: string };
  const [checkbox, setCheckbox] = useState(false);
  const [checkboxError, setCheckboxError] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const [city, setCity] = useState<City | undefined>(undefined);
  const [cityMissing, setCityMissing] = useState<boolean>(false);
  const [professionalOrTeam, setProfessionalOrTeam] = useState<Professional | Team | undefined>(undefined);
  const [professionalMissing, setProfessionalMissing] = useState<boolean>(false);
  const [isSending, setIsSending] = useState(false);
  const [shareSuccessful, setShareSuccessful] = useState(false);

  // Reset checkbox on professional change
  useEffect(() => {
    setCheckbox(false);
  }, [professionalOrTeam]);

  const resetHandler = useCallback(() => {
    setCheckbox(false);
    setCheckboxError(false);
    setCity(undefined);
    setCityMissing(false);
    setProfessionalOrTeam(undefined);
    setProfessionalMissing(false);
    setShareSuccessful(false);
    setErrorMessage(undefined);
  }, []);

  const submitHandler: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      (async () => {
        if (checkbox !== true) {
          setCheckboxError(true);
          return;
        }
        if (city === undefined) {
          setCityMissing(true);
          return;
        }
        if (professionalOrTeam === undefined) {
          setProfessionalMissing(true);
          return;
        }
        try {
          setIsSending(true);
          setErrorMessage(undefined);
          let payload = {};

          if (isProfessional(professionalOrTeam)) {
            payload = {
              professional: {
                externalId: professionalOrTeam.rpps,
                firstName: professionalOrTeam.first_name,
                lastName: professionalOrTeam.last_name,
              },
            };
          } else if (isTeam(professionalOrTeam)) {
            payload = {
              team: {
                id: professionalOrTeam.id,
              },
            };
          }

          await api?.healthRecordApi.share(healthRecordId, payload);

          setShareSuccessful(true);
          onSubmitSuccessful();
        } catch (_e) {
          setErrorMessage(t("common.alerts.alert_notification"));
        } finally {
          setIsSending(false);
        }
      })();
    },
    [api?.healthRecordApi, checkbox, city, healthRecordId, onSubmitSuccessful, professionalOrTeam, t],
  );

  const cityChangeHandler = useCallback((newCity?: City) => {
    setCity(newCity);
    setCityMissing(newCity === undefined);
    setErrorMessage(undefined);
  }, []);

  const professionalChangeHandler = useCallback((newProfessional?: Professional | Team) => {
    setProfessionalOrTeam(newProfessional);
    setProfessionalMissing(newProfessional === undefined);
    setErrorMessage(undefined);
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: "white",
        p: { xs: 2, md: 3 },
        border: `1px solid ${theme.palette.neutral[300]}`,
        mb: 1,
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", mb: 2, ml: -1 }}>
        <ChevronRightIcon sx={{ color: theme.palette.primary.main, mr: 1 }} />
        <Typography variant="h6">{t("share.with_a_health_professional")}</Typography>
      </Box>
      <Box id="form" sx={{ ml: { xs: 1, md: 3 } }}>
        <form onSubmit={submitHandler}>
          {shareSuccessful ? (
            <Alert severity="success">
              <AlertTitle>{t("health_records.sharing_request_sent")}</AlertTitle>
              <Link href="#" onClick={resetHandler}>
                {t("health_records.search_other_pro")}
              </Link>
            </Alert>
          ) : (
            <>
              <Typography variant="body2" component="span">
                {t("share.browse_professional_directory")}
              </Typography>

              <Grid2 container spacing={1} sx={{ marginBottom: 3, marginTop: 1 }}>
                <CityAndProAutocomplete
                  city={city}
                  cityError={cityMissing}
                  onCityChange={cityChangeHandler}
                  professional={professionalOrTeam}
                  professionalError={professionalMissing}
                  onProfessionalChange={professionalChangeHandler}
                  onArianeError={(e) => {
                    if (e) {
                      setErrorMessage(t("common.alerts.alert_notification"));
                    }
                  }}
                />
              </Grid2>
              {professionalOrTeam ? (
                <>
                  {isProfessional(professionalOrTeam) ? (
                    <ProCard pro={professionalOrTeam} />
                  ) : (
                    <TeamCard team={professionalOrTeam} />
                  )}
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                    <StyledCheckbox>
                      <FormControlLabel
                        sx={{
                          color: checkboxError ? "error.main" : "text.primary",
                        }}
                        control={
                          <Checkbox
                            required
                            color={checkboxError ? "error" : "default"}
                            name="vaccineReminder"
                            checked={checkbox}
                            data-testid="consentCheckbox"
                            onChange={(_e, checked) => {
                              setCheckbox(checked);
                              setCheckboxError(checked !== true);
                            }}
                          />
                        }
                        label={
                          <Typography component={"span"} variant="body2">
                            {t("health_records.attestation")}
                          </Typography>
                        }
                      />
                    </StyledCheckbox>
                  </Box>
                </>
              ) : null}
              {errorMessage ? (
                <Alert data-testid="alert" severity="error" sx={{ marginY: 3 }}>
                  {errorMessage}
                </Alert>
              ) : null}
            </>
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 3 }}>
            {!shareSuccessful ? (
              <LoadingButton
                data-testid="submitButton"
                type="submit"
                variant="contained"
                loading={isSending}
                disabled={isSending || checkbox !== true || !professionalOrTeam || !city}
                disableElevation
                startIcon={<ShareIcon />}
              >
                {t("health_records.share")}
              </LoadingButton>
            ) : (
              <Button
                size="large"
                variant="contained"
                onClick={resetHandler}
                disableElevation
                startIcon={<ShareIcon />}
              >
                {t("health_records.search_other_pro")}
              </Button>
            )}
          </Box>
        </form>
      </Box>
    </Box>
  );
}

function isProfessional(object: object): object is Professional {
  return "first_name" in object;
}
function isTeam(object: object): object is Team {
  return "name" in object;
}
