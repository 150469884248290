import MenuIcon from "@mui/icons-material/Menu";
import { AppBar, Box, Grid2, Skeleton, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import * as React from "react";
import { LanguageSelector } from "../components/LanguageSelector";
import { useDrawerToggleHandler } from "./Layout";
import { theme } from "./Theme";
import HelpCenterMenu from "./HelpCenterMenu";

export default function Header({ children, tabs }: { children?: React.ReactNode; tabs?: React.ReactNode }) {
  const handleDrawerToggle = useDrawerToggleHandler();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <AppBar color="inherit" position="sticky" elevation={0}>
      <Toolbar sx={{ py: { xs: 1, md: 3 }, px: { xs: 0, md: 3 } }}>
        <Grid2
          container
          alignItems="center"
          justifyContent="space-between"
          color={theme.palette.neutral[600]}
          sx={{ flexWrap: "nowrap" }}
        >
          <Grid2 container spacing={1} alignItems="center">
            <Grid2 sx={{ display: { lg: "none", xs: "block" } }}>
              <IconButton aria-label="open drawer" onClick={handleDrawerToggle} edge="start" data-testid="menuButton">
                <MenuIcon />
              </IconButton>
            </Grid2>
            <Grid2>
              <Typography variant={breakpointSm ? "h6" : "h5"} component="h1">
                {children ?? <Skeleton variant="text" />}
              </Typography>
            </Grid2>
          </Grid2>
        </Grid2>
        {!breakpointSm && (
          <Box sx={{ display: "inline-flex", alignItems: "center" }}>
            <HelpCenterMenu />
            <LanguageSelector />
          </Box>
        )}
      </Toolbar>
      <AppBar component="div" position="static" elevation={0} sx={{ zIndex: 0, backgroundColor: "inherit" }}>
        {tabs}
      </AppBar>
    </AppBar>
  );
}
