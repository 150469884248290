import { Avatar, Box, Card, CardContent, Divider, Typography, useMediaQuery } from "@mui/material";
import { ReactNode } from "react";
import { theme } from "../../layout/Theme";

export const ShareCard = ({
  primary,
  secondary,
  rightContent,
  icon,
}: {
  primary: string;
  secondary: string;
  rightContent: ReactNode;
  icon: any;
}) => {
  const breakpointMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <Card
      sx={{
        marginTop: 3,
        marginBottom: 2,
        border: `solid 1px ${theme.palette.neutral[400]}`,
      }}
      elevation={0}
    >
      <CardContent>
        <Box display="flex">
          <Box display="flex" sx={{ alignItems: "center", mr: { xs: 2, md: 4 } }}>
            <Avatar
              sx={{ background: theme.palette.primary[100], height: { xs: 56, md: 72 }, width: { xs: 56, md: 72 } }}
            >
              {icon}
            </Avatar>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: { xs: "flex-start", md: "center" },
              flexDirection: { xs: "column", md: "row" },
            }}
          >
            <Box>
              <Typography variant={breakpointMd ? "h6" : "h5"} component="div">
                {primary}
              </Typography>
              <Typography color="text.secondary" variant={breakpointMd ? "body2" : "body1"}>
                {secondary}
              </Typography>
            </Box>
            <Divider
              orientation={breakpointMd ? "horizontal" : "vertical"}
              sx={{ mx: { xs: 0, md: 3 }, my: { xs: 2, md: 0 }, width: { xs: "100%", md: "1px" } }}
            />
            <Typography variant="body2">{rightContent}</Typography>
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};
