import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import SearchIcon from "@mui/icons-material/Search";
import {
  Alert,
  Avatar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  LinearProgress,
  List,
  Paper,
  Snackbar,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { VaccinationAct, VaccinationActCertificator } from "@syadem/kairos-citizen-js";
import { useCallback, useEffect, useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { VaccinationActListSort } from "../../components/VaccinationActListSort";
import VaccinationActsByDiseaseGroups from "../../components/VaccinationActsByDiseaseGroups";
import VaccinationActsByList from "../../components/VaccinationActsByList";
import { IconMedicalInstrumentSyringe } from "../../components/icons/IconMedicalInstrumentSyringe";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { useArianeApi } from "../../hooks/useArianeApi";
import { useDaphne } from "../../hooks/useDaphne";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useHealthRecordVaccinations } from "../../hooks/useHealthRecordVaccinations";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { AddVaccinationSimplified } from "./AddVaccinationSimplified";
import { useQueries } from "../../hooks/useQueries";

export function VaccinationActs() {
  const { id } = useParams() as { id: string };
  const { t } = useI18n();
  const [searchParams] = useSearchParams();
  const [snackOpen, setSnackOpen] = useState<boolean>(searchParams.get("updated") == "true");
  const daphne = useDaphne();
  const arianeApi = useArianeApi();
  const { healthRecord } = useHealthRecord(id);
  const { isLoading, vaccinationActs, error, refreshVaccinations } = useHealthRecordVaccinations(id);
  const [sortedVaccinationActs, setSortedVaccinationActs] = useState<VaccinationAct[]>([]);
  const [certificators, setCertificators] = useState<(Professional | Team)[] | undefined>(undefined);
  const [listType, setListType] = useState("byDiseases");
  const [drawerFormOpen, setDrawerFormOpen] = useState(false);
  const [openDatamatrix, setOpenDatamatrix] = useState(false);
  const closeDrawer = () => {
    setDrawerFormOpen(false);
    setOpenDatamatrix(false);
  };
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const drawerWidth = breakpointSm ? "100%" : "50%";
  const queries = useQueries();

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && sortedVaccinationActs.length === 0 && vaccinationActs && vaccinationActs.length > 0) {
      setSortedVaccinationActs(vaccinationActs);
    }
    return () => {
      isCancelled = true;
    };
  }, [sortedVaccinationActs, vaccinationActs]);

  useEffect(() => {
    (async () => {
      if (vaccinationActs) {
        const certificatorIds = vaccinationActs
          .filter((act) => act.certificator !== null)
          .map((act) => (act.certificator as VaccinationActCertificator).id);

        let actCertificators: (Professional | Team)[] = [];

        if (certificatorIds.length > 0) {
          try {
            actCertificators = await arianeApi.professionals.searchByIds([...new Set(certificatorIds)]);
          } catch (_error) {
            actCertificators = [];
          }
        }

        setCertificators(actCertificators);
      }
    })();
  }, [arianeApi?.professionals, vaccinationActs]);

  const handleDownloadPdf = useCallback(
    async ({ extended }: { extended?: boolean }) => {
      if (!healthRecord) return;

      const blob = await queries.getVaccinationCertificate.call({
        healthRecordId: healthRecord.id,
        extended: extended ?? false,
      });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `${healthRecord.lastName} ${healthRecord.firstNames}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    },
    [healthRecord, queries.getVaccinationCertificate],
  );

  return (
    <>
      <Snackbar
        open={snackOpen}
        autoHideDuration={6000}
        onClose={() => {
          setSnackOpen(false);
        }}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{ ml: "150px" }}
      >
        <Alert severity="success">{t("common.alerts.alert_saved_success")}</Alert>
      </Snackbar>

      <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
        {error && (
          <Typography sx={{ my: 4 }} align="center" component="div">
            <Alert severity="error">{t("common.alerts.alert_notification")}</Alert>
          </Typography>
        )}
        {isLoading && <LinearProgress variant="query" sx={{ my: 2 }} />}
        {!isLoading && healthRecord && daphne && vaccinationActs && vaccinationActs.length > 0 && (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "auto" },
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "center",
                  mt: { xs: 1, md: 0 },
                }}
              >
                <VaccinationActListSort
                  vaccinationActs={vaccinationActs}
                  setSortedVaccinationActs={setSortedVaccinationActs}
                  daphne={daphne}
                  setListType={setListType}
                />
                <Button
                  variant="contained"
                  fullWidth
                  disableElevation
                  startIcon={<SaveAltIcon />}
                  onClick={() => {
                    handleDownloadPdf({ extended: true });
                  }}
                  disabled={vaccinationActs?.length === 0}
                  sx={{ width: { xs: "100%", md: "auto" }, ml: { xs: 0, md: 1 }, mt: { xs: 1, md: 0 } }}
                >
                  {t("common.cta.savePdf")}
                </Button>
              </Box>
              <Box sx={{ display: "inline-flex", alignItems: "center" }}>
                <Button
                  onClick={() => setDrawerFormOpen(true)}
                  startIcon={
                    <Avatar
                      sx={{
                        background: theme.palette.primary[500],
                        width: { xs: 24, md: 32 },
                        height: { xs: 24, md: 32 },
                      }}
                    >
                      <AddIcon fontSize="small" sx={{ color: "white" }} />
                    </Avatar>
                  }
                  sx={{
                    ".MuiButton-startIcon": {
                      minWidth: { xs: "40px", md: "56px" },
                      m: 0,
                    },
                    p: { xs: 1, md: 2 },
                    pr: { xs: 0.5, md: 2 },
                    my: { xs: 1, md: 0 },
                  }}
                  disableElevation
                  variant={breakpointSm ? "outlined" : "text"}
                  fullWidth={breakpointSm}
                >
                  <Typography color="primary" fontWeight="500" sx={{ fontSize: 16 }}>
                    {t("vaccines.add_vaccination")}
                  </Typography>
                </Button>
                <Box sx={{ borderLeft: `1px solid ${theme.palette.neutral[300]}`, pl: 1, ml: 1 }}>
                  <Button
                    variant="outlined"
                    onClick={() => {
                      setDrawerFormOpen(true);
                      setOpenDatamatrix(true);
                    }}
                    startIcon={<SearchIcon />}
                    size={!breakpointSm ? "small" : "large"}
                  >
                    Datamatrix
                  </Button>
                </Box>
              </Box>
            </Box>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.6)">
              {t("vaccines.count", {
                smart_count: sortedVaccinationActs?.length,
              })}
            </Typography>

            <List sx={{ width: "100%" }}>
              {listType === "byList" && (
                <VaccinationActsByList
                  daphne={daphne}
                  certificators={certificators || []}
                  refreshVaccinations={refreshVaccinations}
                  healthRecord={healthRecord}
                  sortedVaccinationActs={sortedVaccinationActs}
                />
              )}
              {listType === "byDiseases" && (
                <VaccinationActsByDiseaseGroups
                  vaccinationActs={vaccinationActs}
                  daphne={daphne}
                  certificators={certificators}
                  reloadCallback={refreshVaccinations}
                  healthRecord={healthRecord}
                />
              )}
            </List>
          </>
        )}
        <Drawer
          anchor="right"
          open={drawerFormOpen}
          onClose={closeDrawer}
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <IconButton onClick={closeDrawer} sx={{ ml: 2 }}>
              <ChevronRightIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box>
            <AddVaccinationSimplified
              isEmbedded
              refreshCallback={refreshVaccinations}
              setSnackOpen={setSnackOpen}
              handleClose={closeDrawer}
              isDatamatrixOpen={openDatamatrix}
            />
          </Box>
        </Drawer>
        {!isLoading && (!vaccinationActs || (vaccinationActs && vaccinationActs.length === 0)) && (
          <EmptyListPlaceholder
            title={t("vaccines.no_vaccinations")}
            icon={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: theme.palette.primary[500],
                  ".iconSyringe": {
                    height: "35px !important",
                  },
                }}
              >
                <IconMedicalInstrumentSyringe />
              </Box>
            }
            button={
              <Button
                size="large"
                onClick={() => setDrawerFormOpen(true)}
                variant="contained"
                startIcon={<AddIcon />}
                disableElevation
              >
                {t("vaccines.add_vaccination")}
              </Button>
            }
          >
            {t("vaccines.add_vaccination_msg")}
          </EmptyListPlaceholder>
        )}
      </Paper>
    </>
  );
}
