import AddIcon from "@mui/icons-material/Add";
import LoadingButton from "@mui/lab/LoadingButton";
import { Alert, Avatar, Box, Grid2, Input, LinearProgress, Paper, Typography } from "@mui/material";
import { ModelError, ResponseError, VaccinationActCertificationMethodEnum } from "@syadem/kairos-citizen-js";
import { Formik } from "formik";
import { orderBy } from "lodash-es";
import { ReactNode, useMemo, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Asserts } from "yup";
import { Country } from "../../../domain/country";
import { getSimplifiedVaccinationsSchema } from "../../../domain/vaccination";
import { formatDateForApi } from "../../../utils/date";
import { dayjs } from "../../../utils/dayjs";
import { TranslateFunction, createTranslateFn } from "../../../utils/formUtils";
import { compareNormalizedString } from "../../../utils/string";
import DatamatrixButton from "../../components/Datamatrix";
import FormVaccinesAutocomplete from "../../components/FormVaccinesAutocomplete";
import { StyledAutocomplete } from "../../components/mui/StyledAutoComplete";
import { CancelLinkButton } from "../../components/mui/StyledButtons";
import StyledDatepicker from "../../components/mui/StyledDatepicker";
import { AddVaccinationsList } from "../../components/vaccination/AddVaccinationsList";
import { useCountryConfig } from "../../hooks/useCountryConfig";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { useKairosApi } from "../../hooks/useKairosApi";
import { useVaccinesWithDiseases } from "../../hooks/useVaccinesWithDiseases";
import { theme } from "../../layout/Theme";
import { BoosterForm } from "../../components/vaccination/BoosterForm";

function AddVaccinations() {
  const { id: healthRecordId } = useParams() as {
    id: string;
  };
  const { t, getObject } = useI18n();
  const translateErrors: TranslateFunction = useMemo(() => createTranslateFn(t), [t]);
  const { healthRecord, isLoading: healthRecordIsLoading } = useHealthRecord(healthRecordId);
  const { isLoading: isLoadingVaccinesWithDiseases } = useVaccinesWithDiseases();
  const [countryCodeInput, setCountryCodeInput] = useState<string | undefined>(undefined);
  const [errorMessage, setErrorMessage] = useState<string | ReactNode | undefined>(undefined);
  const vaccinationsSchema = getSimplifiedVaccinationsSchema(healthRecord?.birthDate);
  const [vaccinations, setVaccinations] = useState<Asserts<typeof vaccinationsSchema>[] | undefined>(undefined);
  const [isSavingAll, setIsSavingAll] = useState(false);
  // Submission
  const { pathname } = useLocation();
  const countries = useMemo(() => {
    return Object.entries(getObject("countries")).reduce(
      (acc: Country[], [key, value]) => [...acc, { code: key, name: value }],
      [],
    );
  }, [getObject]);
  const api = useKairosApi();
  const navigate = useNavigate();
  const { defaultCountryCode } = useCountryConfig();

  const saveAll = () => {
    if (api) {
      setIsSavingAll(true);
      setErrorMessage(undefined);
      const promises = vaccinations?.map((vaccination) => {
        return api.vaccinationActApi.createVaccinationAct(healthRecordId, {
          vaccinationAct: {
            booster: vaccination.booster,
            countryCode: vaccination.countryCode,
            vaccineId: vaccination.vaccineId,
            performedOn: formatDateForApi(vaccination.performedOn),
          },
        });
      });
      Promise.all(promises || [])
        .then(() => {
          setIsSavingAll(false);
          setVaccinations(undefined);
          navigate(`..?updated=true`);
        })
        .catch(async (err) => {
          setIsSavingAll(false);
          if (
            (err instanceof Response && err.status === 400) ||
            (err instanceof ResponseError && err.response.status === 400)
          ) {
            const response = err instanceof Response ? err : err.response;
            const code = ((await response.json()) as ModelError).code;

            switch (code) {
              case "act_cannot_be_performed_in_the_future_or_before_patient_birth_date":
                setErrorMessage(t("vaccines.error_messages.error_1"));
                break;
              case "act_cannot_be_performed_after_vaccine_expiration_date":
                setErrorMessage(t("vaccines.error_messages.error_2"));
                break;
              case "incorrect_injection_method":
                setErrorMessage(t("vaccines.error_messages.error_3"));
                break;
              case "incorrect_injection_location":
                setErrorMessage(t("vaccines.error_messages.error_4"));
                break;
            }
          } else {
            setErrorMessage(t("common.alerts.alert_notification"));
          }
        });
    }
  };

  return (
    <>
      {isLoadingVaccinesWithDiseases && healthRecordIsLoading && <LinearProgress variant="query" />}
      {!isLoadingVaccinesWithDiseases && (
        <Paper
          sx={{ overflow: "hidden", border: `solid 1px ${theme.palette.neutral[200]}`, p: { xs: 2, md: 4 } }}
          elevation={0}
        >
          <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
            <Box flex={1}>
              <Formik
                initialValues={{
                  vaccineId: "",
                  countryCode: defaultCountryCode,
                  performedOn: dayjs().startOf("day").utc().toDate(),
                  certificationMethod: VaccinationActCertificationMethodEnum.Proof,
                  booster: false,
                }}
                validationSchema={vaccinationsSchema}
                onSubmit={async (values) => {
                  setVaccinations((prevState) => [...(prevState || []), values]);
                }}
              >
                {({
                  values,
                  errors,
                  getFieldProps,
                  touched,
                  handleSubmit,
                  setFieldValue,
                  setFieldTouched,
                  isSubmitting,
                }) => (
                  <form onSubmit={handleSubmit} noValidate autoComplete="off">
                    <Input
                      type="text"
                      id="PreventChromeAutocomplete"
                      name="PreventChromeAutocomplete"
                      autoComplete="address-level4"
                      sx={{ display: "none" }}
                    />

                    <Grid2 container rowSpacing={2} justifyContent="space-between">
                      <Grid2 size={12} container justifyContent="space-between" marginBottom={2}>
                        <Grid2 container>
                          <Avatar
                            sx={{
                              background: theme.palette.primary[500],
                              color: theme.palette.primary[100],
                              mr: 2,
                            }}
                          >
                            1
                          </Avatar>
                          <Typography variant="h6">{t("vaccines.add_vaccination")}</Typography>
                        </Grid2>
                      </Grid2>
                      <Grid2
                        container
                        rowSpacing={2}
                        sx={{ border: `solid 1px ${theme.palette.neutral[200]}`, borderRadius: 1, p: 2, mt: 0 }}
                      >
                        <Grid2>
                          <DatamatrixButton />
                        </Grid2>
                        {errorMessage ? (
                          <Grid2 size={12}>
                            <Alert severity="error">{errorMessage}</Alert>
                          </Grid2>
                        ) : null}
                        <Grid2 size={12}>
                          <StyledDatepicker
                            {...getFieldProps("performedOn")}
                            placeholder={t("common.dates.datePlaceholder")}
                            label={t("vaccines.date_act")}
                            testId="performedOn"
                            required
                            // fullWidth
                            minDate={dayjs(healthRecord?.birthDate)}
                            maxDate={dayjs()}
                            onChange={(date) => {
                              setFieldTouched("performedOn", true, false);
                              setFieldValue("performedOn", date ? date.toDate() : "");
                            }}
                            error={touched.performedOn && !!errors.performedOn}
                            errorMessage={errors.performedOn}
                            translateErrors={translateErrors}
                            touched={!!touched.performedOn}
                          />
                        </Grid2>
                        <Grid2 size={12}>
                          <StyledAutocomplete
                            id="countryCode"
                            label={t("common.user_infos.country")}
                            error={touched.countryCode && !!errors.countryCode}
                            errorMessage={errors.countryCode}
                            touched={touched.countryCode}
                            translateErrors={translateErrors}
                            fullWidth
                            placeholder="ex : France"
                            required
                            options={countries}
                            filterOptions={(x) =>
                              x.filter((e) => compareNormalizedString(e.name, countryCodeInput || ""))
                            }
                            getOptionLabel={(country: Country) => country.name || ""}
                            onOpen={() => setFieldTouched("countryCode", true)}
                            value={countries.find((country) => country.code == values.countryCode) || null}
                            onInputChange={(_event, newCountryCode, reason) => {
                              if (reason === "input") {
                                setCountryCodeInput(newCountryCode);
                              }
                            }}
                            onChange={(_event, newCountryCode) => {
                              setFieldTouched("countryCode", true);
                              setFieldValue("countryCode", newCountryCode?.code);
                              if (!newCountryCode) {
                                setCountryCodeInput(undefined);
                                setFieldValue("countryCode", "");
                              }
                            }}
                          />
                        </Grid2>
                        <Grid2 size={12} container>
                          <FormVaccinesAutocomplete />
                        </Grid2>
                        <Grid2 size={12} container columnSpacing={4} rowSpacing={2}>
                          <BoosterForm birthDate={healthRecord?.birthDate} vaccinationDate={values.performedOn} />
                        </Grid2>
                        <Grid2 size={12} container justifyContent="flex-end" sx={{ mt: 2 }}>
                          <LoadingButton
                            variant="contained"
                            disableElevation
                            type="submit"
                            loading={isSubmitting}
                            startIcon={<AddIcon />}
                            data-testid="submit"
                          >
                            {t("common.cta.add")}
                          </LoadingButton>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  </form>
                )}
              </Formik>
            </Box>
            <Box flex={1} ml={{ xs: 0, md: 3 }} mt={{ xs: 4, md: 0 }}>
              <Grid2 container>
                <Avatar
                  sx={{
                    background: theme.palette.primary[500],
                    color: theme.palette.primary[100],
                    mr: 2,
                  }}
                >
                  2
                </Avatar>
                <Typography variant="h6" marginBottom={2}>
                  {t("vaccines.addVaccinations.title")}
                </Typography>
              </Grid2>
              {vaccinations && vaccinations?.length > 0 ? (
                <AddVaccinationsList
                  vaccinationActs={orderBy(vaccinations, (vaccinationAct) => vaccinationAct.performedOn, "asc")}
                  birthDate={healthRecord?.birthDate as Date}
                  deleteVaccinationAct={(index: number) => {
                    setVaccinations((prevState) => prevState?.filter((_, i) => i !== index));
                  }}
                />
              ) : (
                <Paper
                  elevation={0}
                  sx={{
                    background: theme.palette.neutral[100],
                    border: `solid 1px ${theme.palette.neutral[200]}`,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    p: 3,
                  }}
                >
                  <Typography variant="body2" textAlign="center">
                    {t("vaccines.addVaccinations.no_vaccines_added")}
                  </Typography>
                </Paper>
              )}
            </Box>
          </Box>
          <Grid2 container rowSpacing={2} justifyContent="space-between" marginTop={4}>
            <Grid2 container size={12} justifyContent="space-between">
              <Box sx={{ display: "inline-flex" }}>
                <CancelLinkButton
                  component={Link}
                  to={`${pathname.substring(0, pathname.lastIndexOf("/health-records") + 15)}/${healthRecordId}`}
                  variant="contained"
                  disableElevation
                >
                  {t("common.cta.cancel")}
                </CancelLinkButton>
              </Box>
              <LoadingButton
                variant="contained"
                disableElevation
                loading={isSavingAll}
                data-testid="saveVaccinations"
                disabled={!vaccinations || vaccinations?.length === 0}
                onClick={saveAll}
              >
                {t("common.cta.save")}
              </LoadingButton>
            </Grid2>
          </Grid2>
        </Paper>
      )}
    </>
  );
}

export default AddVaccinations;
