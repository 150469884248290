import { Alert } from "@mui/material";
import { theme } from "../../layout/Theme";
import { useI18n } from "../../hooks/useI18n";
import { Team } from "@syadem/ariane-js";
import { ShareCard } from "./ShareCard";
import GroupsIcon from "@mui/icons-material/Groups";

export function TeamCard(props: { team: Team }) {
  const { team } = props;
  const { t } = useI18n();
  return <>
    <Alert
      severity="info"
      sx={{
        my: 2,
        bgcolor: theme.palette.primary[100],
        color: theme.palette.primary[600],
        border: `solid 1px ${theme.palette.primary[200]}`,
        ".MuiAlert-icon": {
          color: theme.palette.primary[600],
        },
      }}
    >
      {t("share.share_with_team_disclaimer")}
    </Alert>
    <ShareCard
      primary={team.name}
      secondary={`${t("team.identificationNumber")}: ${team.id}`}
      icon={<GroupsIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />}
      rightContent={
        <>
          {team.address}
          <br />
          {`${team.zip_code} ${team.city.toUpperCase()}`}
        </>
      }
    />
  </>
}
