import { Checkbox, FormControlLabel, Grid2, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getVaccinationSchema } from "../../../domain/vaccination";
import { useI18n } from "../../hooks/useI18n";
import { useVaccineWithDiseases } from "../../hooks/useVaccineWithDiseases";
import { StyledCheckbox } from "../mui/StyledCheckbox";
import { useFormikContext } from "formik";
import { Asserts } from "yup";
import { dayjs } from "../../../utils/dayjs";

export const BoosterForm = ({
  isReadonly = false,
  birthDate,
  vaccinationDate,
}: {
  isReadonly?: boolean;
  birthDate: Date | undefined;
  vaccinationDate: Date | undefined;
}) => {
  const _vaccinationSchema = getVaccinationSchema();
  const { values, handleChange, setFieldValue } = useFormikContext<Asserts<typeof _vaccinationSchema>>();
  const { t } = useI18n();
  const [isVisible, setIsVisible] = useState(values?.booster !== undefined ? values?.booster : false);
  const vaccineWithDiseases = useVaccineWithDiseases(values?.vaccineId);

  useEffect(() => {
    if (birthDate) {
      if (vaccineWithDiseases) {
        //Display booster if age is more or equal than 5
        const isMoreOrEqualFive = vaccinationDate && dayjs(dayjs(vaccinationDate)).diff(birthDate, "year") >= 5;

        //And if disease equal to Diphteria or Tetanos or Polio
        const specificDiseases = ["Diphtérie", "Tétanos", "Poliomyélite"];
        const isDiphteriaOrTetanosOrPolio = vaccineWithDiseases?.diseases.some((disease) =>
          specificDiseases.includes(disease.name["fr"] as string),
        );
        if (isMoreOrEqualFive && isDiphteriaOrTetanosOrPolio) {
          setIsVisible(true);
        } else {
          setIsVisible(false);
          setFieldValue("booster", false);
        }
      }
    }
  }, [vaccineWithDiseases, vaccinationDate, birthDate, setFieldValue]);

  useEffect(() => {
    if (!isVisible) {
      setFieldValue("booster", false);
    }
  }, [setFieldValue, isVisible]);

  return isVisible ? (
    <Grid2 size={{ xs: 12, lg: 6 }}>
      <StyledCheckbox label={"Type"} fullWidth disabled={isReadonly} testId="booster">
        <FormControlLabel
          control={
            <Checkbox
              onChange={handleChange}
              checked={values?.booster}
              name="booster"
              value="yes"
              size="small"
              data-testid="boosterCheckbox"
            />
          }
          label={<Typography variant="body2">{t("vaccines.booster")}</Typography>}
        />
      </StyledCheckbox>
    </Grid2>
  ) : null;
};
