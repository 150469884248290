import {
  Box,
  Button,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
} from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { HealthRecord } from "@syadem/kairos-citizen-js";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProCard } from "../components/sharing/ProCard";
import { TeamCard } from "../components/sharing/TeamCard";
import { useAllHealthRecords } from "../hooks/useAllHealthRecords";
import { useArianeApi } from "../hooks/useArianeApi";
import { useI18n } from "../hooks/useI18n";
import { useServiceBus } from "../hooks/useServiceBus";
import { PageLayout } from "../layout/PageLayout";

export function QuickShare() {
  const id = useParams<{ id: string }>().id;
  const [professionalOrTeam, setProfessionalOrTeam] = useState<Professional | Team | null | undefined>(undefined);
  const serviceBus = useServiceBus();
  const i18n = useI18n();
  const healthRecords = useAllHealthRecords();
  const arianeApi = useArianeApi();
  const [checked, setChecked] = useState<string[]>([]);
  const handleToggle = (value: string) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setChecked(newChecked);
  };

  useEffect(() => {
    if (!id) return;
    arianeApi.professionals.searchById(id).then((pro) => {
      console.log(pro);
      setProfessionalOrTeam(pro);
    });
  }, [id, arianeApi.professionals]);

  if (!id) {
    return "Not found";
  }

  if (professionalOrTeam === undefined) {
    return "Loading";
  }
  if (professionalOrTeam === null) {
    return "Unknown pro";
  }
  if (healthRecords.isLoading) {
    return "Loading";
  }

  return (
    <PageLayout title={i18n.t("quick_share.title")}>
      {isProfessional(professionalOrTeam) ? (
        <ProCard pro={professionalOrTeam} />
      ) : (
        <TeamCard team={professionalOrTeam} />
      )}
      <Paper>
        <HealthRecordList healthRecords={healthRecords.healthRecords} select={handleToggle} selected={checked} />
        <Box style={{ display: "flex", justifyContent: "flex-end", padding: "16px" }}>
          <Button
            variant="contained"
            onClick={() =>
              serviceBus.dispatch({
                type: "shareHealthRecords",
                healthRecordsIds: checked,
                proOrTeam: professionalOrTeam,
              })
            }
          >
            Share
          </Button>
        </Box>
      </Paper>
    </PageLayout>
  );
}

function HealthRecordList(props: {
  healthRecords: HealthRecord[] | undefined;
  selected: string[];
  select: (id: string) => () => void;
}) {
  const { healthRecords, select, selected } = props;

  if (!healthRecords) {
    return null;
  }
  return (
    <List sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}>
      {healthRecords.map((hr) => {
        const label = `${hr.firstNames} - ${hr.lastName}`;
        return (
          <ListItem key={hr.id}>
            <ListItemButton role={undefined} onClick={select(hr.id)} dense>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selected.indexOf(hr.id) !== -1}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ "aria-labelledby": label }}
                />
              </ListItemIcon>
              <ListItemText id={hr.id} primary={label} />
            </ListItemButton>
          </ListItem>
        );
      })}
    </List>
  );
}

function isProfessional(object: object): object is Professional {
  return "first_name" in object;
}
