import { Alert, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useKairosApi } from "../../hooks/useKairosApi";
import { PageLayout } from "../../layout/PageLayout";
import { HealthRecordForm, HealthRecordFormHandler } from "./HealthRecordForm";
import { useI18n } from "../../hooks/useI18n";

export function NewHealthRecord() {
  const { t } = useI18n();

  const api = useKairosApi();
  const navigate = useNavigate();

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);

  const onSubmit: HealthRecordFormHandler = useCallback(
    (body) => {
      (async () => {
        try {
          const response = await api?.healthRecordApi.createHealthRecord(body);

          if (response === undefined) {
            setErrorDisplayed(true);
          } else {
            navigate(`/health-records/${response.healthRecord.id}`);
          }
        } catch (_error) {
          setErrorDisplayed(true);
        }
      })();
    },
    [api?.healthRecordApi, navigate],
  );

  return (
    <PageLayout title={t("health_records.add_hr")}>
      <HealthRecordForm onSubmit={onSubmit} />
      <Snackbar
        open={errorDisplayed}
        autoHideDuration={6000}
        onClose={() => setErrorDisplayed(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{t("common.alerts.alert_classic")}</Alert>
      </Snackbar>
    </PageLayout>
  );
}
