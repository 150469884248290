import { Box, Button, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import StyledDialog from "../../components/mui/StyledDialog";
import { useI18n } from "../../hooks/useI18n";
import { AddVaccination } from "./AddVaccination";
import AddVaccinations from "./AddVaccinations";

function AddVaccinationType({ initialType = "simple" }: { initialType?: string }) {
  const { t } = useI18n();
  const [formType, setFormType] = useState(initialType);

  const handleChange = (_event: React.MouseEvent<HTMLElement>, newFormType: string) => {
    if (newFormType) {
      setFormType(newFormType);
    }
  };

  const [searchParams] = useSearchParams();
  const origin = searchParams.get("origin");

  const [open, setOpen] = useState(origin === "hpv");
  const navigate = useNavigate();
  const location = useLocation();
  const onCloseModal = () => {
    setOpen(false);
    const urlWithoutSearchParams = location.pathname;
    navigate(urlWithoutSearchParams, { replace: true });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ToggleButtonGroup
          color="primary"
          value={formType}
          exclusive
          onChange={handleChange}
          aria-label="Platform"
          sx={{ mb: 3 }}
        >
          <ToggleButton value="simple">{t("vaccines.addVaccinationType.simple_entry")}</ToggleButton>
          <ToggleButton value="multiple">{t("vaccines.addVaccinationType.multiple_entry")}</ToggleButton>
        </ToggleButtonGroup>
        {formType === "simple" && <AddVaccination />}
        {formType === "multiple" && <AddVaccinations />}
      </Box>
      <StyledDialog
        open={open}
        onClose={onCloseModal}
        title={t("vaccines.addVaccinationType.modal.title")}
        hideCancelAction
        dialogActions={
          <Button variant="contained" disableElevation sx={{ ml: "auto" }} onClick={onCloseModal}>
            {t("common.cta.close")}
          </Button>
        }
      >
        {t("vaccines.addVaccinationType.modal.description")}
      </StyledDialog>
    </>
  );
}

export default AddVaccinationType;
