import { Professional, Team } from "@syadem/ariane-js";
import { Service } from "./service";
import { Apis } from "../network/apis";
import { AppRouter } from "../appConfig";

export class ShareHealthRecordsService implements Service<{ healthRecordsIds: string[], proOrTeam: Professional | Team }, void> {
  api: Apis;
  router: AppRouter;
  constructor(api: Apis, router: AppRouter) {
    this.api = api;
    this.router = router;
  }

  async call(payload: { healthRecordsIds: string[], proOrTeam: Professional | Team }) {
    const { healthRecordsIds, proOrTeam } = payload;
    let requestBody = {};
    if (this.isProfessional(proOrTeam)) {
      requestBody = {
        professional: {
          externalId: proOrTeam.rpps,
          firstName: proOrTeam.first_name,
          lastName: proOrTeam.last_name,
        },
      };
    } else if (this.isTeam(proOrTeam)) {
      requestBody = {
        team: {
          id: proOrTeam.id,
        },
      };
    }
    healthRecordsIds.forEach(async (healthRecordId) => {
      await this.api.healthRecordApi.share(healthRecordId, requestBody);
    });

    this.router.navigate("/health-records");
  }

  private isProfessional(professionalOrTeam: Professional | Team): professionalOrTeam is Professional {
    return (professionalOrTeam as Professional).rpps !== undefined;
  }

  private isTeam(professionalOrTeam: Professional | Team): professionalOrTeam is Team {
    return (professionalOrTeam as Team).id !== undefined;
  }

}