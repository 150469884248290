import { Professional } from "@syadem/ariane-js";
import { useI18n } from "../../hooks/useI18n";
import { ShareCard } from "./ShareCard";
import { theme } from "../../layout/Theme";
import PersonIcon from "@mui/icons-material/Person";

export function ProCard(props: { pro: Professional }) {
  const { pro } = props;
  const { t } = useI18n();

  return <ShareCard
    primary={`${pro.first_name} ${pro.last_name}`}
    secondary={
      pro.rpps
        ? `${t("healthProfessional.rppsNumber")}: ${pro.rpps}`
        : t("healthProfessional.noRppsNumber")
    }
    icon={<PersonIcon fontSize="large" sx={{ color: theme.palette.primary.main }} />}
    rightContent={
      <>
        {pro.address}
        <br />
        {`${pro.zip_code} ${pro.city?.toUpperCase()}`}
      </>
    }
  />
}