import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import SortByAlphaIcon from "@mui/icons-material/SortByAlpha";
import { Box, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { Daphne } from "@syadem/daphne-js";
import { VaccinationAct } from "@syadem/kairos-citizen-js";
import { orderBy } from "lodash-es";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";
import { IconMedicalInstrumentSyringe } from "./icons/IconMedicalInstrumentSyringe";

interface VaccinationActListSortProps {
  vaccinationActs: VaccinationAct[] | undefined;
  setSortedVaccinationActs: Dispatch<SetStateAction<VaccinationAct[]>>;
  daphne: Daphne | null;
  setListType: React.Dispatch<React.SetStateAction<string>>;
}

export function VaccinationActListSort(props: VaccinationActListSortProps) {
  const { vaccinationActs, setSortedVaccinationActs, daphne, setListType } = props;
  const { t, locale } = useI18n();
  const [sortCriteria, setSortCriteria] = useState<string>("group-by-diseases");

  useEffect(() => {
    if (vaccinationActs && daphne) {
      const sortedList = sortList(vaccinationActs, sortCriteria, daphne, locale as string);
      setSortedVaccinationActs(sortedList);
    }
  }, [vaccinationActs, setSortedVaccinationActs, daphne, sortCriteria, locale]);

  const handleChange = useCallback(
    (event: SelectChangeEvent<string>) => {
      if (setListType) setListType(event.target.value === "group-by-diseases" ? "byDiseases" : "byList");
      setSortCriteria(event.target.value);
    },
    [setListType],
  );

  return (
    <FormControl
      size="small"
      disabled={!daphne || !vaccinationActs}
      sx={{
        backgroundColor: "background.paper",
        width: { xs: "100%", md: 300 },
        borderRadius: 1,
        ".MuiOutlinedInput-notchedOutline": { borderColor: theme.palette.neutral[300] },
      }}
    >
      <InputLabel id="sort-vaccination-acts-select">{t("common.interface.sort_by")}</InputLabel>
      <Select labelId="sort-vaccination-acts-select" value={sortCriteria} label="Trier par" onChange={handleChange}>
        <MenuItem value="group-by-diseases">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: theme.palette.neutral[400],
                ".iconSyringe": {
                  height: "24px !important",
                  width: "24px !important",
                },
              }}
            >
              <IconMedicalInstrumentSyringe />
            </Box>
            <SortByAlphaIcon color="disabled" sx={{ width: "20px" }} />
            <Typography sx={{ marginLeft: "5px" }}>{t("vaccines.displayByDiseases")}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value="performed-on-asc">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarMonthIcon color="disabled" />
            <ArrowDownwardIcon fontSize="small" color="disabled" />
            <Typography sx={{ marginLeft: "5px" }}>{t("vaccines.administration_date")}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value="performed-on-desc">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <CalendarMonthIcon color="disabled" />
            <ArrowUpwardIcon fontSize="small" color="disabled" />
            <Typography sx={{ marginLeft: "5px" }}>{t("vaccines.administration_date")}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value="vaccine-name-asc">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SortByAlphaIcon color="disabled" />
            <ArrowDownwardIcon fontSize="small" color="disabled" />
            <Typography sx={{ marginLeft: "5px" }}>{t("vaccines.name")}</Typography>
          </Box>
        </MenuItem>
        <MenuItem value="vaccine-name-desc">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <SortByAlphaIcon color="disabled" />
            <ArrowUpwardIcon fontSize="small" color="disabled" />
            <Typography sx={{ marginLeft: "5px" }}>{t("vaccines.name")}</Typography>
          </Box>
        </MenuItem>
      </Select>
    </FormControl>
  );
}

function sortList(
  vaccinationActs: VaccinationAct[],
  criteria: string,
  daphne: Daphne,
  locale: string,
): VaccinationAct[] {
  let sortedList = vaccinationActs;

  switch (criteria) {
    case "performed-on-asc":
      sortedList = orderBy(vaccinationActs, (vaccinationAct) => vaccinationAct.performedOn, "asc");
      break;
    case "performed-on-desc":
      sortedList = orderBy(vaccinationActs, (vaccinationAct) => vaccinationAct.performedOn, "desc");
      break;
    case "vaccine-name-asc":
      sortedList = orderBy(
        vaccinationActs,
        (vaccinationAct) => {
          const vaccine = daphne.repositories.vaccines.find(vaccinationAct.vaccineId);
          return getTranslatedFieldByLocale(locale, vaccine.name);
        },
        "asc",
      );
      break;
    case "vaccine-name-desc":
      sortedList = orderBy(
        vaccinationActs,
        (vaccinationAct) => {
          const vaccine = daphne.repositories.vaccines.find(vaccinationAct.vaccineId);
          return getTranslatedFieldByLocale(locale, vaccine.name);
        },
        "desc",
      );
      break;
  }

  return sortedList;
}
