export const fr = {
  quick_share: {
    title: "Partage rapide",
  },
  notifications: {
    unmanaged_error: "Une erreur inattendue s'est produite",
  },
  users: {
    citizen: "Citoyen",
    pro: "Professionnel de santé",
    team: "Équipe de soin",
  },
  injectionLocations: {
    left_deltoid: "Deltoïde gauche",
    right_deltoid: "Deltoïde droit",
    left_thigh: "Cuisse gauche",
    right_thigh: "Cuisse droite",
    null: "",
  },
  injectionMethods: {
    intramuscular: "Intramusculaire",
    subcutaneous: "Sous-cutanée",
    intradermal: "Intradermique",
    oral: "Orale",
    intranasal: "Intranasale",
    null: "",
  },
  sharingRequestStatus: {
    accepted: "Acceptée",
    pending: "En attente",
    rejected: "Déclinée",
  },
  patient: "Patients",
  pro: "Professionnel de santé",
  recommandations: {
    DiagnosticList: {
      infosMessage: "La prise en compte du nouveau calendrier vaccinal 2024 est en cours.",
    },
  },
  diagnostic: {
    conclusion: {
      up_to_date: "À jour",
      late: "En retard",
      to_do: "À faire",
    },
    nextVaccination: "Prochain vaccin le %{targetedDate}",
    specialCase: "Cas particulier signalé",
    contraindicated: "Contre-indication signalée",
  },
  profile: {
    closeQuestionnaireCta: "Fermer le questionnaire santé",
    noConditions: "Aucune caractéristique",
    addConditionCta: "Ajouter une caractéristique",
    shortTitle: "Profil",
    conditionsTitle: "Caractéristiques renseignées",
    questionnaireTitle: "Complétez ici le profil santé",
    deleteCondition: {
      title: "Supprimer la caractéristique",
      content: "Êtes-vous sûr de vouloir supprimer cette caractéristique ?",
    },
    validated: "Profil validé",
    validated_info_msg:
      "Un profil santé validé par un professionnel ne peut plus être modifié par le titulaire du carnet. Pour toute modification concernant ce profil, voir un professionnel de santé (médecin, pharmacien, infirmière ou sage-femme).",
    not_validated_info_msg:
      "Vous pouvez partager votre carnet afin qu'un professionnel de santé en valide le profil santé et les vaccins reçus",
    validatedBy: "Validé par %{certificator}",
    validatedByAPro: "Validé par un professionnel de santé",
  },
  notes: {
    title: "Notes",
    unknownAuthor: "Auteur inconnu",
    lastModified: "Modifiée il y a %{distance}",
    addedAt: "Ajoutée il y a %{distance}",
  },
  common: {
    subscribed:
      "Le professionnel, en temps que membre du réseau MesVaccins.net applique les bonnes pratiques de vaccination et peut valider votre historique vaccinal ou celui de vos proches.",
    subscribedLogo:
      "Le logo indique que le professionnel, en temps que membre du réseau MesVaccins.net applique les bonnes pratiques de vaccination et peut valider votre historique vaccinal ou celui de vos proches.",
    helpCenter: "Centre d'aide",
    createHelpTicket: "Nous contacter",
    and: "et",
    others: "Autres",
    loading: "Chargement...",
    navbar: {
      title: "Recommandations vaccinales",
      subtitle: "Pour vous et vos proches",
    },
    copied: "Copié !",
    cta: {
      previous: "Précédent",
      back: "Retour",
      next: "Suivant",
      save: "Enregistrer",
      add: "Ajouter",
      edit: "Modifier",
      delete: "Supprimer",
      cancel: "Annuler",
      print: "Imprimer",
      close: "Fermer",
      reset: "Réinitialiser",
      submit: "Soumettre",
      savePdf: "PDF",
      validate: "Valider",
      logout: "Déconnexion",
      accept: "Accepter",
      reject: "Refuser",
      remove: "Retirer",
      search: "Rechercher",
      view: "Consulter",
      copy: "Copier",
      send: "Envoyer",
    },
    errors: {
      title: "Erreur %{statusCode}",
      title_unknown: "Erreur inconnue",
      "404": "La page demandée n'existe pas.",
      "401": "Vous n'avez pas l'autorisation d'accéder à cette page.",
      default: "Une erreur s'est produite, notre équipe technique a été prévenue.",
      noResults: "Aucun résultat",
    },
    gender: {
      m: "Masculin",
      f: "Féminin",
      s: "Sexe",
    },
    conditions: {
      at: "à",
      equalTo: "est égal à",
    },
    dates: {
      datePlaceholder: "JJ/MM/AAAA",
      dateFormat: "dd/MM/yyyy",
      "DD/MM/YYYY": "JJ/MM/AAAA",
      "MM/DD/YYYY": "MM/JJ/AAAA",
      bornThe: "né(e) le %{birthDate}",
      day: "jour |||| jours",
      month: "mois |||| mois",
      week: "semaine |||| semaines",
      year: "an |||| ans",
      toDoIn: "A faire dans",
      toDoAsap: "A faire dès que possible",
      lastModified: "Modifié il y a %{distance}",
      addedAt: "Ajouté il y a %{distance}",
      processedAt: "Traité il y a %{distance}",
    },
    interface: {
      language: "langage",
      patients: "Ma Patientèle",
      unshared_health_records: "Carnets départagés",
      pending_sharing_requests: "Partages en attente",
      informations: "Mes informations",
      sort_by: "Trier par",
      back_to_home: "Retour à l'accueil",
      reconnect: "Me reconnecter",
    },
    user_infos: {
      title: "Nous vous invitons à vérifier l’exactitude des informations fournies",
      first_names: "Prénom(s)",
      first_name: "Prénom",
      last_name: "Nom de naissance",
      usual_name: "Nom d'usage",
      customary_name: "Nom d'usage",
      birth_date: "Date de naissance",
      social_security_number: "N° sécurité sociale",
      social_security_number_infos:
        "Veuillez renseigner le numero de sécurité sociale pour préremplir le sexe, le mois et l'année de naissance",
      phone: "Téléphone",
      address: "Adresse",
      zip_code: "Code postal du lieu de résidence",
      city: "Ville",
      country: "Pays",
      placeholder: "Rechercher par nom, date de naissance et/ou NIR",
      birth_place: "Lieu de naissance",
      birth_zip_code: "Code postal de naissance",
      birth_country: "Pays de naissance",
    },
    alerts: {
      alert_notification: "Une erreur s'est produite, notre équipe technique a été prévenue.",
      alert_classic: "Une erreur s'est produite",
      alert_profile: "Votre profil a été mis à jour.",
      alert_auth: "Une erreur s'est produite lors de l'authentification, notre équipe technique a été prévenue.",
      alert_auth_unknown_error: "Erreur d'authentification",
      alert_logout: "Vous êtes déconnecté",
      alert_logout_long: "Votre session s'est terminée, veuillez vous reconnecter.",
      alert_saved_success: "Modifications enregistrées",
      alert_auth_failed: "Échec de l'authentification",
    },
    city: {
      placeholder: "ex: Bordeaux ou 33000",
    },
  },
  informations: {
    title: "Votre compte",
  },
  sharing_requests: {
    status: "Aucun partage en attente",
    pending_requests: "Partage en attente",
    notification: "Vous serez notifié si le carnet d'un patient est partagé avec vous.",
    error: "Une erreur s'est produite",
    success: "Demande traitée avec succès",
    reject: "Refuser la demande de partage",
    reject_validation: "Êtes-vous sur de vouloir refuser cette demande de partage ?",
  },
  patients: {
    add: "Ajouter un carnet",
    none: "Aucun carnet",
    nothing: "Non renseigné",
    title: "Carnets",
    tabs: {
      immunization: "Vaccins reçus",
      vaccinations_to_be_done: "Statut vaccinal",
      profile: "Profil santé",
      identity: "Identité",
      share: "Partage & transfert",
    },
  },
  vaccines: {
    count: "%{smart_count} vaccination |||| %{smart_count} vaccinations",
    placeholder: "ex : Vaccin Grippe",
    distributionDate: "disponible depuis le %{date}",
    title: "Vaccinations",
    error_messages: {
      error_1: "La vaccination ne peut pas être effectuée dans le futur ou avant la date de naissance du patient.",
      error_2: "La vaccination ne peut pas être effectuée après la date d'expiration de la vaccination.",
      error_3: "La méthode d'injection n'est pas correcte.",
      error_4: "Le lieu d'administration n'est pas correct.",
    },
    validate: "Valider le vaccin",
    validated: "Acte validé",
    not_validated: "Acte non validé",
    aProfessional: "un professionnel de santé",
    aTeam: "une équipe de soin",
    aTeamMember: "un professionnel de l'équipe %{teamInfo}",
    validatedUponExecutionBy: "Validé le %{date} car administré par %{certificator}",
    validatedUponProofBy: "Validé sur preuve le %{date} par %{certificator}",
    editableByProOnly: "Seul le professionnel de santé ayant réalisé cet acte peut le modifier",
    editableByTeamOnly: "Seul un professionnel de santé de l'équipe de soins ayant réalisé cet acte peut le modifier",
    editableByAnyPro: "Seul un professionnel de santé peut le modifier",
    confirmation: {
      validate: "Je valide cet acte sur présentation d'un certificat ou carnet de vaccination.",
      do_validate: "J'ai réalisé cet acte et je le valide.",
    },
    no_vaccinations: "Aucune vaccination enregistrée",
    no_vaccinations_sub: "Aucune vaccination présente sur ce carnet à la date d'arrêt du partage.",
    administration_date: "Date d'administration",
    administration: "Administré le %{date} à %{age}.",
    administration_country: "Administré le %{date} à %{age} en %{country}.",
    administered_the: "Administré le ",
    administered_at: " à ",
    name: "Nom du vaccin",
    name_or_disease: "Nom du vaccin ou de la maladie",
    add_vaccination: "Ajouter une vaccination",
    add_vaccination_msg: "Ajoutez vos vaccinations pour faire le suivi de votre état vaccinal.",
    view_vaccination: "Consulter une vaccination",
    edit_vaccination: "Modifier une vaccination",
    delete_vaccination: "Supprimer la vaccination",
    delete_vaccination_validation: "Êtes-vous sur de vouloir supprimer cette vaccination ?",
    date_act: "Date de l'acte",
    act_date_greater_than_expiration_date: "A la date de l’acte, la date d’expiration est atteinte",
    expiration_date: "Date d'expiration",
    more: "Afficher plus de vaccins",
    serial_number: "Numéro de série",
    batch_number: "Numéro de lot",
    booster: "Rappel",
    injection_location: "Lieu d'administration",
    injection_path: "Voie d'administration",
    additional_information: "Données détaillées",
    printCertified: "Seuls les vaccins validés sont imprimables.",
    addVaccinationConfirmed: "Vaccination ajoutée avec succès",
    addVaccinations: {
      title: "Vaccins à enregistrer",
      no_vaccines_added: "Aucun vaccin ajouté, veuillez utiliser le formulaire",
    },
    addVaccinationType: {
      simple_entry: "Saisie simple",
      simple_entry_subtitle: "Saisie d un seul vaccin avec tous ses détails",
      multiple_entry: "Saisie multiple",
      multiple_entry_subtitle: "Saisie de plusieurs vaccins sans détails",
      modal: {
        title: "Mise à jour du carnet de vaccinations",
        description:
          "Pour assurer la meilleure protection de votre enfant, veuillez mettre à jour ou vérifier les informations concernant ses vaccinations. Cela ne prendra que quelques minutes et vous aidera à garder son dossier médical complet et à jour. Accédez à la section des vaccinations maintenant et assurez-vous que tout est en ordre.",
      },
    },
    addSimilarVaccination: {
      title: "Vaccination similaire",
      details:
        "Une vaccination avec un vaccin similaire, à la même date, existe déjà pour ce patient. Êtes-vous sûr de vouloir l'ajouter ?",
    },
    vaccinesDistributed: "Vaccins disponibles à la date indiquée",
    otherVaccines: "Autres vaccins (disponibles à l’étranger, génériques ou anciens)",
    distributedSince: "Disponible depuis le %{distributionStart}",
    distributedBetween: "Disponible du %{distributionStart} au %{distributionEnd}",
    displayByDiseases: "Maladie",
  },
  health_records: {
    title: "Mes carnets",
    success: "Carnet mis à jour avec succès",
    add_success: "Carnet ajouté avec succès",
    open_added_hr: "Ouvrir le carnet ajouté",
    delete_hr: "Supprimer le carnet",
    delete_hr_validation_intro:
      "Cette opération est irréversible : le carnet, les vaccins et profil santé associés seront définitivement supprimés.",
    delete_hr_validation: "Êtes-vous sur de vouloir supprimer ce carnet ?",
    none: "Aucun carnet",
    add_hr: "Ajouter un carnet",
    add_this_hr: "Ajouter ce carnet",
    add_shared_hr: "Ajouter un carnet partagé",
    need_connection_title: "Authentification en cours...",
    need_connection:
      "Pour récupérer ce carnet, vous devez vous inscrire ou vous connecter à votre compte MesVaccins.net.",
    none_unshared: "Aucun carnet départagé",
    none_shared: "Aucun partage",
    share_button: "Partager ce carnet",
    share_button_2: "Partager le carnet",
    share_pros: "Partager ce carnet avec d'autres professionnels de santé",
    share_pro: "Partager avec un professionnel de santé",
    share_msg: "Partagez votre carnet avec des professionnels de santé et avec vos proches",
    share_msg_2: "Créez des nouveaux carnets ou accédez à ceux de vos proches via le partage",
    sharing_request_sent: "Demande de partage envoyée",
    cancel_invite: "Annuler l'invitation",
    cancel_invite_validation: "Êtes-vous sûr de vouloir annuler cette invitation ?",
    search_other_pro: "Chercher un autre professionnel de santé",
    share: "Partager",
    copy_share_link: "Copier le lien de partage",
    copy_share_link_msg: "En cliquant ci-dessous, vous copierez dans votre press-papier le lien de partage.",
    copy_share_link_success: "Le lien a bien été copié dans le presse-papier.",
    attestation: "J'atteste être autorisé à effectuer ce partage de carnet",
    history: "Retrouvez ici l'historique des carnets auxquels vous avez eu accès par le passé",
    create_or_access: "Créez des nouveaux patients ou accédez à leur carnet via le partage",
    remove_from_patients: "Retirer le carnet de la patientèle",
    remove_from_patients_validation: "Êtes-vous sur de vouloir retirer ce carnet de votre patientèle ?",
    revoke: "Révoquer",
    revoke_access: "Révoquer l'accès",
    revoke_access_start: "Etes-vous sûr de vouloir révoquer l’accès au carnet de ",
    revoke_access_end_citizen: " :  il n’aura plus accès aux évolutions de ce carnet.",
    revoke_access_end_pro: " :  ce professionnel n’aura plus accès aux évolutions de ce carnet.",
    revoke_access_end_team:
      " :  les professionnels de cette équipe de soins n’auront plus accès aux évolutions de ce carnet.",
  },
  share: {
    identity_error: {
      title: "Identité incomplète",
      description: "Afin de pouvoir partager votre carnet de vaccination, veuillez compléter votre identité",
      cta: "Compléter mon identité",
    },
    shared: "Partagé",
    browse_professional_directory:
      "Parcourez l'annuaire afin de sélectionner le professionnel de santé ou l'équipe de soins à qui vous souhaitez donner accès à ce carnet.",
    errors: {
      error_1:
        "Date de naissance incorrecte. Vous avez dépassé le nombre de tentatives possible, veuillez demander un autre lien de partage au propriétaire du carnet.",
      error_2: "Date de naissance incorrecte. Il vous reste une tentative pour ajouter le carnet.",
      error_3: "Date de naissance incorrecte. Il vous reste %{remaining_attempts} tentatives pour ajouter le carnet.",
      error_4: "Lien de partage incorrect ou nombre de tentatives dépassé.",
      error_5: "Une erreur s'est produite, notre équipe technique a été prévenue. Merci de réessayer plus tard.",
    },
    with_a_health_professional: "Partager ce carnet avec un professionnel de santé",
    with_another_user: "Partager ce carnet avec un proche",
    with_its_owner: "Transférer ce carnet",
    share_the_health_record: "Partager le carnet",
    grant_ownership_disclaimer_1:
      "Vous êtes sur le point de partager ce carnet avec un proche. Par exemple : Transférer le carnet à votre enfant devenu majeur.",
    grant_ownership_disclaimer_2: "En cliquant ci-dessous, vous copiez dans votre presse-papier un lien de partage.",
    grant_ownership_disclaimer_3:
      "Une fois le lien copié, vous pourrez le coller dans un e-mail, un SMS, etc. et l'envoyer à la personne concernée",
    share_with_citizen_disclaimer_1:
      "Vous êtes sur le point de partager ce carnet avec un proche. Par exemple : Partager le carnet d’un enfant avec un autre parent.",
    link_copied: "Lien copié",
    generate_sharing_link: "Partager",
    transfer: "Transférer",
    link_copied_to_clipboard: "Le lien a bien été copié dans le presse-papier",
    copy_sharing_link: "Copier le lien de partage",
    email_sharing: "Partagez ce carnet avec un proche en envoyant un e-mail :",
    email_grant_ownership: "Transférez ce carnet à un proche en envoyant un e-mail :",
    share_link: "Copiez le lien ci-dessous et partagez-le à votre convenance avec un proche :",
    email_sharing_description:
      "Précisez au proche que le lien n'est valide que 30 jours. S'il ne le reçoit pas immédiatement, il doit vérifier ses SPAMS.",
    grant_ownership_link: "Copiez le lien ci-dessous et partagez-le à votre convenance :",
    share_link_description: "Ce lien peut être partagé par SMS ou e-mail.",
    qrcode_share: "Faites scanner ce QrCode à un proche pour partager le carnet avec lui :",
    qrcode_share_description: "Méthode rapide : à privilégier si le proche a un smartphone.",
    qrcode_grant_ownership: "Faites scanner ce QrCode par son futur propriétaire :",
    or: "OU",
    sms_grant_ownership: "Donnez la propriéte du carnet directement en envoyant un sms :",
    sms_share: "Donnez accès au carnet en envoyant un sms :",
    withdraw: "Retirer",
    withdraw_hr: "Retirer le carnet",
    withdraw_hr_validation: "Êtes-vous sûr de vouloir supprimer votre accès à ce carnet ?",
    rpps_number: "Numéro RPPS: ",
    security_msg: "Par mesure de sécurité, merci de renseigner la date de naissance du propriétaire du carnet partagé.",
    attest: "J'atteste être autorisé à accéder à ce carnet",
    share_with_team_disclaimer:
      "Note: Un carnet partagé avec une équipe de soins sera accessible par tous les professionnels de santé qui la composent.",
    other_share_methods: "Autres moyens de partage (QrCode, lien)",
    other_transfer_methods: "Autres moyens de transfert (QrCode, lien)",
    generate_pdf: "Imprimer le QrCode",
  },
  pdfView: {
    shareHealthRecord: "Ce carnet vous est partagé par %{user}",
    qrCode: "Ce QRCode est valide jusqu'au %{date}",
    vaccinesList: "Liste des vaccins du patient à ce jour (%{date})",
  },
  account: {
    email: "Email",
    password: "Mot de passe",
    mobile: "Numéro de téléphone mobile",
    infos:
      "Adresse e-mail et mot de passe sont vos identifiants de connexion. Le numéro de téléphone mobile permet une authentification à 2 facteurs, garant de la sécurité de votre compte. Aucun usage commercial ne sera fait de vos données personnelles.",
    vaccination_reminders: "Me prévenir par email des prochaines vaccinations",
    vaccination_reminders_infos:
      "Cochez cette case si vous souhaitez être notifié des vaccinations en retard ou à faire dans les 30 prochains jours.",
  },
  datamatrix: {
    dialogTitle: "Scanner un code datamatrix",
    preventionMethod: "Vaccin: ",
    serialNumber: "Numéro du série: ",
    batchNumber: "Numéro de lot: ",
    expirationDate: "Date d'expiration: ",
    scanCta: "Scanner le code Datamatrix",
    loading: "Interrogation des serveurs",
    noDeviceError: "Aucune caméra n'a été détectée.",
    notFoundToast:
      "Aucune correspondance de vaccin n’a été trouvée sur nos serveurs, merci de bien vouloir préciser le nom du vaccin.",
    scanError: "Il y a eu un problème lors de la lecture du code Datamatrix, veuillez réessayer.",
    serverError: "Il y a eu un problème lors de l'interrogation de la base de vaccins, veuillez réessayer.",
    successToast: "Le formulaire a bien été prérempli avec les résultats du scan.",
    camera: {
      tabTitle: "Camera",
      steps: {
        "1": "Mettre le code datamatrix dans le carré jaune",
        "2": "La recherche d'informations est automatique",
        "3": "La popup se fermera automatiquement et pré-remplira le formulaire",
      },
    },
    barCodeScanner: {
      tabTitle: "Lecteur de code barre",
      steps: {
        "1": "Scanner le code avec le lecteur de code barre",
        "2": "Le code apparaitra dans le champ texte",
        "3": "En cliquant sur le lecteur de code barre la popup se fermera automatiquement et pré-remplira le formulaire",
      },
    },
    inverseImage: "Inverser l'image de la caméra",
  },
  healthProfessional: {
    rppsNumber: "Numéro RPPS",
    noRppsNumber: "Aucun numéro RPPS",
  },
  team: {
    identificationNumber: "Numéro d'identification",
  },
  yup: {
    mixed: {
      default: "est invalide",
      required: "est obligatoire",
      oneOf: "doit avoir une des valeurs suivantes: %{values}",
      notOneOf: "ne doit pas avoir une des valeurs suivantes: %{values}",
      defined: "est obligatoire",
    },
    string: {
      length: "doit faire exactement %{length} caractères",
      min: "doit faire au moins %{min} caractères",
      max: "doit faire moins de %{max} caractères",
      matches: "est invalide et doit respecter le format requis",
      email: "doit être un email valide",
      url: "doit être une URL valide",
      uuid: "doit être un identifiant unique valide",
      trim: "ne doit pas commencer ou terminer par un espace",
      lowercase: "doit être en minuscule",
      uppercase: "doit être en majuscule",
    },
    number: {
      min: "doit être supérieur ou égal à %{min}",
      max: "doit être inférieur ou égale à %{max}",
      lessThan: "doit être inférieur à %{less}",
      moreThan: "doit être supérieur à %{more}",
      positive: "doit être un nombre positif",
      negative: "doit être un nombre négatif",
      integer: "doit être un nombre entier",
    },
    date: {
      min: "doit être le %{min} ou après",
      max: "doit être le %{max} ou avant",
      invalid: "n'est pas une date valide",
    },
    boolean: {
      isValue: "doit avoir la valeur %{value}",
    },
    object: {
      noUnknown: "doit contenir les clés: %{unknown}",
    },
    array: {
      min: "doit contenir au moins %{min} éléments",
      max: "doit contenir au maximum %{max} éléments",
      length: "doit contenir %{length} éléments",
    },
  },
  countries: {
    AFG: "Afghanistan",
    ALB: "Albanie",
    DZA: "Algérie",
    ASM: "Samoa américaines",
    AND: "Andorre",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctique",
    ATG: "Antigua-et-Barbuda",
    ARG: "Argentine",
    ARM: "Arménie",
    ABW: "Aruba",
    AUS: "Australie",
    AUT: "L'Autriche",
    AZE: "Azerbaïdjan",
    BHS: "Bahamas (les)",
    BHR: "Bahreïn",
    BGD: "Bangladesh",
    BRB: "Barbade",
    BLR: "Biélorussie",
    BEL: "Belgique",
    BLZ: "Bélize",
    BEN: "Bénin",
    BMU: "Bermudes",
    BTN: "Bhoutan",
    BOL: "Bolivie (État plurinational de)",
    BES: "Bonaire, Saint Eustache et Saba",
    BIH: "Bosnie Herzégovine",
    BWA: "Botswana",
    BVT: "Île Bouvet",
    BRA: "Brésil",
    IOT: "Territoire britannique de l'océan Indien (le)",
    BRN: "Brunei Darussalam",
    BGR: "Bulgarie",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    CPV: "Cap-Vert",
    KHM: "Cambodge",
    CMR: "Cameroun",
    CAN: "Canada",
    CYM: "Îles Caïmans (les)",
    CAF: "République centrafricaine (la)",
    TCD: "Tchad",
    CHL: "Chili",
    CHN: "Chine",
    CXR: "L'île de noël",
    CCK: "Îles Cocos (Keeling) (les)",
    COL: "Colombie",
    COM: "Comores (les)",
    COD: "Congo (République démocratique du)",
    COG: "Congo (le)",
    COK: "Îles Cook (les)",
    CRI: "Costa Rica",
    HRV: "Croatie",
    CUB: "Cuba",
    CUW: "Curacao",
    CYP: "Chypre",
    CZE: "Tchéquie",
    CIV: "Côte d'Ivoire",
    DNK: "Danemark",
    DJI: "Djibouti",
    DMA: "Dominique",
    DOM: "République dominicaine (la)",
    ECU: "Equateur",
    EGY: "Egypte",
    SLV: "Le Salvador",
    GNQ: "Guinée Équatoriale",
    ERI: "Erythrée",
    EST: "Estonie",
    SWZ: "Eswatini",
    ETH: "Ethiopie",
    FLK: "Îles Falkland (les) [Malouines]",
    FRO: "Îles Féroé (les)",
    FJI: "Fidji",
    FIN: "Finlande",
    FRA: "France",
    GUF: "Guyane Française",
    PYF: "Polynésie française",
    ATF: "Terres australes françaises (les)",
    GAB: "Gabon",
    GMB: "Gambie (la)",
    GEO: "Géorgie",
    DEU: "Allemagne",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Grèce",
    GRL: "Groenland",
    GRD: "Grenade",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernesey",
    GIN: "Guinée",
    GNB: "Guinée-Bissau",
    GUY: "Guyane",
    HTI: "Haïti",
    HMD: "Île Heard et îles McDonald",
    VAT: "Saint-Siège (le)",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hongrie",
    ISL: "Islande",
    IND: "Inde",
    IDN: "Indonésie",
    IRN: "Iran (République islamique d)",
    IRQ: "Irak",
    IRL: "Irlande",
    IMN: "Île de Man",
    ISR: "Israël",
    ITA: "Italie",
    JAM: "Jamaïque",
    JPN: "Japon",
    JEY: "Jersey",
    JOR: "Jordan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Corée (République populaire démocratique de)",
    KOR: "Corée (la République de)",
    KWT: "Koweit",
    KGZ: "Kirghizistan",
    LAO: "Laos / République Démocratique Populaire Lao",
    LVA: "Lettonie",
    LBN: "Liban",
    LSO: "Lesotho",
    LBR: "Libéria",
    LBY: "Libye",
    LIE: "Liechtenstein",
    LTU: "Lituanie",
    LUX: "Luxembourg",
    MAC: "Macao",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaisie",
    MDV: "Maldives",
    MLI: " Mali ",
    MLT: "Malte",
    MHL: "Îles Marshall (les)",
    MTQ: " Martinique ",
    MRT: "Mauritanie",
    MUS: "Maurice",
    MYT: "Mayotte",
    MEX: "Mexique",
    FSM: "Micronésie (États fédérés de)",
    MDA: "Moldavie (République de)",
    MCO: "Monaco",
    MNG: "Mongolie",
    MNE: "Monténégro",
    MSR: "Montserrat",
    MAR: "Maroc",
    MOZ: "Mozambique",
    MMR: "Myanmar (Birmanie)",
    NAM: "Namibie",
    NRU: "Nauru",
    NPL: "Népal",
    NLD: "Pays-Bas (les)",
    NCL: "Nouvelle Calédonie",
    NZL: "Nouvelle-Zélande",
    NIC: "Nicaragua",
    NER: "Niger (le)",
    NGA: "Nigeria",
    NIU: "Niué",
    NFK: "L'ile de Norfolk",
    MNP: "Îles Mariannes du Nord (les)",
    NOR: "Norvège",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palaos",
    PSE: "Palestine, État de",
    PAN: "Panama",
    PNG: "Papouasie Nouvelle Guinée",
    PRY: "Paraguay",
    PER: "Pérou",
    PHL: "Philippines (les)",
    PCN: "Pitcairn",
    POL: "Pologne",
    PRT: "Le Portugal",
    PRI: "Porto Rico",
    QAT: "Qatar",
    MKD: "République de Macédoine du Nord",
    ROU: "Roumanie",
    RUS: "Fédération de Russie (la)",
    RWA: "Rwanda",
    REU: "Réunion",
    BLM: "Saint Barthélemy",
    SHN: "Sainte Hélène, Ascension et Tristan da Cunha",
    KNA: "Saint-Christophe-et-Niévès",
    LCA: "Sainte-Lucie",
    MAF: "Saint Martin (partie française)",
    SPM: "Saint-Pierre-et-Miquelon",
    VCT: "Saint-Vincent-et-les-Grenadines",
    WSM: "Samoa",
    SMR: "Saint Marin",
    STP: "Sao Tomé et Principe",
    SAU: "Arabie Saoudite",
    SEN: "Sénégal",
    SRB: "Serbie",
    SYC: "Les Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapour",
    SXM: "Sint Maarten (partie hollandaise)",
    SVK: "Slovaquie",
    SVN: "Slovénie",
    SLB: "Îles Salomon",
    SOM: "Somalie",
    ZAF: "Afrique du Sud",
    SGS: "Géorgie du Sud et îles Sandwich du Sud",
    SSD: "Soudan du sud",
    ESP: "Espagne",
    LKA: "Sri Lanka",
    SDN: "Soudan (le)",
    SUR: "Surinam",
    SJM: "Svalbard et Jan Mayen",
    SWE: "Suède",
    CHE: "Suisse",
    SYR: "République arabe syrienne",
    TWN: "Taiwan, Province de Chine)",
    TJK: "Tadjikistan",
    TZA: "Tanzanie, République-Unie de",
    THA: "Thaïlande",
    TLS: "Timor Oriental",
    TGO: "Togo",
    TKL: "Tokélaou",
    TON: "Tonga",
    TTO: "Trinité-et-Tobago",
    TUN: "Tunisie",
    TUR: "Turquie",
    TKM: "Turkménistan",
    TCA: "Îles Turques et Caïques (les)",
    TUV: "Tuvalu",
    UGA: "Ouganda",
    UKR: "Ukraine",
    ARE: "Emirats Arabes Unis (les)",
    GBR: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord (le)",
    UMI: "Îles mineures éloignées des États-Unis (les)",
    USA: "États-Unis d'Amérique (les)",
    URY: "Uruguay",
    UZB: "Ouzbékistan",
    VUT: "Vanuatu",
    VEN: "Venezuela (République bolivarienne du)",
    VNM: "Vietnam",
    VGB: "Îles Vierges (britanniques)",
    VIR: "Îles Vierges (États-Unis)",
    WLF: "Wallis et Futuna",
    ESH: "Sahara occidental",
    YEM: "Yémen",
    ZMB: "Zambie",
    ZWE: "Zimbabwe",
    ALA: "Iles Aland",
  },
};

export type TranslationObject = typeof fr;
