import { TranslationObject } from "./fr";

export const lv: TranslationObject = {
  quick_share: {
    title: "Share health records",
  },
  notifications: {
    unmanaged_error: "An unexpected error occurred"
  },
  users: {
    citizen: "Citizen",
    pro: "Medical professional",
    team: "Healthcare team",
  },
  injectionLocations: {
    left_deltoid: "Left deltoid",
    right_deltoid: "Right deltoid",
    left_thigh: "Left thigh",
    right_thigh: "Right thigh",
    null: "",
  },
  injectionMethods: {
    intramuscular: "Intramuscular",
    subcutaneous: "Subcutaneous",
    intradermal: "Intradermal",
    oral: "Oral",
    intranasal: "Intranasal",
    null: "",
  },
  sharingRequestStatus: {
    accepted: "Accepted",
    pending: "Pending",
    rejected: "Rejected",
  },
  patient: "Patients",
  pro: "Medical professional",
  recommandations: {
    DiagnosticList: {
      infosMessage: "The new 2024 vaccination schedule is currently being taken into account.",
    },
  },
  diagnostic: {
    conclusion: {
      up_to_date: "Up to date",
      late: "Late",
      to_do: "To do",
    },
    nextVaccination: "Next vaccination on %{targetedDate}",
    specialCase: "Special case reported",
    contraindicated: "Reported contraindication",
  },
  profile: {
    closeQuestionnaireCta: "Close the health questionnaire",
    noConditions: "No characteristic",
    addConditionCta: "Add a characteristic",
    shortTitle: "Profile",
    conditionsTitle: "Characteristics filled",
    questionnaireTitle: "Complete the health profile here",
    deleteCondition: {
      title: "Delete the characteristic",
      content: "Are you sure you want to reject this characteristic?",
    },
    validated: "Validated profile",
    validated_info_msg:
      "A health profile validated by a professional can no longer be modified by the health record holder. For any modification concerning this profile, see a health professional (doctor, pharmacist, nurse or midwife)",
    not_validated_info_msg: "Your health profile has not been validated.",
    validatedBy: "Validated by %{certificator}",
    validatedByAPro: "Validated by a health professional",
  },
  notes: {
    title: "Notes",
    unknownAuthor: "Unknown author",
    lastModified: "Last updated %{distance} ago",
    addedAt: "Added %{distance} ago",
  },
  common: {
    subscribed:
      "The professional, as a member of the MyVaccines.net network applies good vaccination practices and can validate your vaccination history or that of your loved ones",
    subscribedLogo:
      "The logo indicates that the professional, as a member of the MyVaccines.net network, applies good vaccination practices and can validate your vaccination history or that of your family.",
    helpCenter: "Help center",
    createHelpTicket: "Contact us",
    and: "and",
    others: "Others",
    loading: "Loading...",
    navbar: {
      title: "Vaccine Recommendations",
      subtitle: "For you and your loved ones",
    },
    copied: "Copied !",
    cta: {
      previous: "Previous",
      back: "Back",
      next: "Next",
      save: "Save",
      add: "Add",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      print: "Print",
      close: "Close",
      reset: "Reset",
      submit: "Submit",
      savePdf: "PDF",
      validate: "Validate",
      logout: "Logout",
      accept: "Accept",
      reject: "Reject",
      remove: "Remove",
      search: "Search",
      view: "Consult",
      copy: "Copier",
      send: "Envoyer",
    },
    errors: {
      title: "Error %{statusCode}",
      title_unknown: "Unknown error",
      404: "The requested page doesn't exist.",
      401: "You don't have access to the requested page.",
      default: "Something unexpected happened, our technical team has been notified.",
      noResults: "No results",
    },
    gender: {
      m: "Male",
      f: "Female",
      s: "Sexe",
    },
    conditions: {
      at: "at",
      equalTo: "is equal to",
    },
    dates: {
      datePlaceholder: "DD/MM/YYYY",
      dateFormat: "dd/MM/yyyy",
      "DD/MM/YYYY": "DD/MM/YYYY",
      "MM/DD/YYYY": "MM/DD/YYYY",
      bornThe: "born the %{birthDate}",
      day: "day |||| days",
      month: "month |||| months",
      week: "week |||| weeks",
      year: "year |||| years",
      toDoIn: "To do in",
      toDoAsap: "To do asap",
      lastModified: "Last updated %{distance} ago",
      addedAt: "Added %{distance} ago",
      processedAt: "Processed %{distance} ago",
    },
    interface: {
      language: "language",
      patients: "My Patients",
      unshared_health_records: "Unshared health records",
      pending_sharing_requests: "Pending sharing requests",
      informations: "My informations",
      sort_by: "Sort by",
      back_to_home: "Back to home",
      reconnect: "Reconnect",
    },
    user_infos: {
      title: "We invite you to check the accuracy of the information provided",
      first_names: "First name(s)",
      first_name: "First name",
      last_name: "Last name",
      usual_name: "Usual name",
      customary_name: "Customary name",
      birth_date: "Birth date",
      social_security_number: "Social security number",
      social_security_number_infos:
        "Please fill in the social security number to pre-populate the gender, month and year of birth",
      phone: "Phone number",
      address: "Addresse",
      zip_code: "Postal code of place of residence",
      city: "City",
      country: "Country",
      placeholder: "Search by name, birth date, and/or NIR",
      birth_place: "Place of birth",
      birth_zip_code: "Postal code of birth",
      birth_country: "Country of birth",
    },
    alerts: {
      alert_notification: "An error occurred, our technical team has been notified.",
      alert_classic: "An error occurred.",
      alert_profile: "Your profile has been updated.",
      alert_auth: "An error occurred during the authentication process, our technical team has been notified.",
      alert_auth_unknown_error: "Authentication error",
      alert_logout: "You have been disconnected",
      alert_logout_long: "Your session has expired, please reconnect.",
      alert_saved_success: "Update successfull",
      alert_auth_failed: "Authentication Failed",
    },
    city: {
      placeholder: "ex: Bordeaux or 33000",
    },
  },
  informations: {
    title: "Complete your profile",
  },
  sharing_requests: {
    status: "No pending sharing requests",
    pending_requests: "Sharing pending",
    notification: "You will be notified in case a patient's health record is shared with you.",
    error: "An error occurred.",
    success: "Request processed successfully.",
    reject: "Reject sharing request",
    reject_validation: "Are you sure you want to reject this sharing request ?",
  },
  patients: {
    add: "Add a health record",
    none: "No health record",
    nothing: "No infos",
    title: "Health records",
    tabs: {
      immunization: "Immunization",
      vaccinations_to_be_done: "Vaccination status",
      profile: "Health profile",
      identity: "Identity",
      share: "Sharing & transfer",
    },
  },
  vaccines: {
    count: "%{smart_count} vaccination |||| %{smart_count} vaccinations",
    placeholder: "ex : Flu vaccine",
    distributionDate: "available since %{date}",
    title: "Vaccinations",
    error_messages: {
      error_1: "The vaccination act cannot be performed in the future or beore the birth date.",
      error_2: "The vaccination act cannot be performed after the vaccine expiration date.",
      error_3: "The injection method is incorrect",
      error_4: "The administration location is incorrect",
    },
    validate: "Validate vaccination",
    validated: "Validated act",
    not_validated: "Unvalidated act",
    aProfessional: "a health professional",
    aTeam: "a healthcare team",
    aTeamMember: "a professional from the team %{teamInfo}",
    validatedUponExecutionBy: "Validated on %{date} upon execution by %{certificator}",
    validatedUponProofBy: "Validated upon proof on %{date} by %{certificator}",
    editableByProOnly: "Only the health professional who performed this act can edit it",
    editableByTeamOnly: "Only members of the healthcare team which performed this act can edit it",
    editableByAnyPro: "Only a health professional can edit it",
    confirmation: {
      validate: "I validate this act upon presentation of a certificate of a health record",
      do_validate: "I Performed this act and I validate it",
    },
    no_vaccinations: "No vaccination registered",
    no_vaccinations_sub: "No vaccination present on this health record on the date of cessation of sharing",
    administration_date: "Administration date",
    administered_the: "Administered the ",
    administration: "Administered the %{date} at %{age} old.",
    administration_country: "Administered the %{date} at %{age} old in ${country}.",
    administered_at: " at ",
    name: "Vaccine's name",
    name_or_disease: "Vaccine's or disease's name",
    add_vaccination: "Add vaccination",
    add_vaccination_msg: "Add your vaccinations to track your vaccination status.",
    view_vaccination: "Consult a vaccination",
    edit_vaccination: "Edit vaccination",
    delete_vaccination: "Delete vaccination",
    delete_vaccination_validation: "Are you sure you want to delete this vaccination ?",
    date_act: "Date of vaccination",
    act_date_greater_than_expiration_date: "On the date of the act, the expiration date has been reached",
    expiration_date: "Expiration date",
    more: "Show more Vaccines",
    serial_number: "Serial number",
    batch_number: "Batch number",
    booster: "Rappel",
    injection_location: "Administration location",
    injection_path: "Method of administration",
    additional_information: "Detailed datas",
    printCertified: "Only validated vaccines can be printed",
    addVaccinationConfirmed: "Vaccination added with success",
    addVaccinations: {
      title: "Vaccines to save",
      no_vaccines_added: "No vaccines added, please use the form",
    },
    addVaccinationType: {
      simple_entry: "Simple entry",
      simple_entry_subtitle: "Entry of a single vaccine with all its details",
      multiple_entry: "Multiple entry",
      multiple_entry_subtitle: "Entry of several vaccines without details",
      modal: {
        title: "Updating the health record",
        description:
          "To ensure the best protection of your child, please update or verify their vaccination information. This will only take a few minutes and will help you keep their medical records complete and up to date. Go to the vaccinations section now and make sure Please make sure everything is in order.",
      },
    },
    addSimilarVaccination: {
      title: "Similar vaccination",
      details:
        "A vaccination with a similar vaccine, on the same date, already exists for this patient. Are you sure you want to add it?",
    },
    vaccinesDistributed: "Vaccines available on the date indicated",
    otherVaccines: "Other vaccines (available abroad, generic or older)",
    distributedSince: "Available since %{distributionStart}",
    distributedBetween: "Available from ${distributionStart} to %{distributionEnd}",
    displayByDiseases: "Disease",
  },
  health_records: {
    title: "My health records",
    success: "Health record successfully updated.",
    add_success: "Health record successfully added.",
    open_added_hr: "Open added health record",
    delete_hr: "Delete health record",
    delete_hr_validation_intro:
      "This operation is irreversible: the record, associated vaccinations and health profile will be permanently deleted",
    delete_hr_validation: "Are you sure you want to delete this health record ?",
    none: "No health record",
    add_hr: "Add a health record",
    add_this_hr: "Add this health record",
    add_shared_hr: "Add a shared health record",
    need_connection_title: "Authenticating...",
    need_connection: "To retrieve this health record, you must register or login to your MyVaccines.net account.",
    none_unshared: "No unshared health record.",
    none_shared: "No share",
    share_button: "Share this health record",
    share_button_2: "Share health record",
    share_pros: "Share your health record with other health professionals",
    share_pro: "Share with a health professional",
    share_msg: "Share this health record with health professionals and with your family",
    share_msg_2: "Create new health records or access those of your family via sharing",
    sharing_request_sent: "Sharing request sent.",
    cancel_invite: "Cancel invite",
    cancel_invite_validation: "Are you sure you want to cancel this invite ?",
    search_other_pro: "Search for another health professional",
    share: "Share",
    copy_share_link: "Copy share link",
    copy_share_link_msg: "By clicking below, you will copy into your print-sharing link..",
    copy_share_link_success: "Link copied to clipboard.",
    attestation: "I certify that I'm autorized to share this health record.",
    history: "Find here the history of the all the health records you've had access to",
    create_or_access: "Create new patients or access existing health records via the share functionnality",
    remove_from_patients: "Remove health record from my patients",
    remove_from_patients_validation: "Are you sure you want to remove this record from your patient base?",
    revoke: "Revoke",
    revoke_access: "Revoke access",
    revoke_access_start: "Are you sure you want to revoke access to the logbook of ",
    revoke_access_end_citizen: ": this professional will no longer have access to the evolutions of this book",
    revoke_access_end_pro: ": this professional will no longer have access to the evolutions of this book",
    revoke_access_end_team:
      ": the professionals of this care team will no longer have access to the evolutions of this notebook.",
  },
  share: {
    identity_error: {
      title: "Incomplete identity",
      description: "In order to share your health record, please complete your identity",
      cta: "Complete my identity",
    },
    shared: "Shared",
    browse_professional_directory:
      "Browse the directory to select the health professional or the healthcare team you want to grant access to your health record.",
    errors: {
      error_1:
        "Incorrect date of birth. You have exceeded the number of possible attempts, please ask the health record owner for another sharing link.",
      error_2: "Incorrect date of birth. You still have one attempt to add the health record.",
      error_3: "Incorrect date of birth. You still have %{remaining_attempts} attempts to add the health record.",
      error_4: "Incorrect sharing link or number of attempts exceeded.",
      error_5: "An error has occurred, our technical team has been notified. Please try again later.",
    },
    with_a_health_professional: "Share this health record with a health professional",
    with_another_user: "Share this health record with a friend",
    with_its_owner: "Transfer this health record",
    share_the_health_record: "Share this health record",
    grant_ownership_disclaimer_1:
      "You are about to share this health record with a relative. For example: Transfer the health record to your adult child.",
    grant_ownership_disclaimer_2: "By clicking below, you will copy a sharing link to your clipboard.",
    grant_ownership_disclaimer_3:
      "Once you have copied the link, you can paste it into an email, SMS, etc. and send it to the person concerned.",
    share_with_citizen_disclaimer_1:
      "You are about to share this health record with a friend. For example: Sharing a child's health record with another parent.",
    link_copied: "Link copied",
    generate_sharing_link: "Share",
    transfer: "Transfer",
    link_copied_to_clipboard: "Link has been copied to clipboard",
    copy_sharing_link: "Copy sharing link",
    email_sharing: "Share by sending an email:",
    email_sharing_description:
      "Tell the nearest that the link is only valid for 30 days. If they do not receive it immediately, they should check their SPAMS.",
    email_grant_ownership: "Transfer this notebook with a friend or family member by sending an email:",
    share_link: "Copy this link and share it as you want with a relative",
    grant_ownership_link: "Copy this link and share it as you want",
    share_link_description: "This link can be shared by SMS or e-mail.",
    qrcode_share: "Have a friend or family member scan this QrCode to share the notebook with them:",
    qrcode_share_description: "Quick method : to be preferred if the nearest has a smartphone.",
    qrcode_grant_ownership: "Scan qrcode to become owner",
    or: "OR",
    sms_grant_ownership: "Give ownership by sending a sms :",
    sms_share: "Give access by sending a sms :",
    withdraw: "Withdraw",
    withdraw_hr: "Withdraw health record",
    withdraw_hr_validation: "Are you sure you want to withdraw your access to this health record ?",
    rpps_number: "RPPS number: ",
    security_msg: "As a security measure, please enter the birth date of the owner of the shared health_record",
    attest: "I certify that I am authorized to access this book",
    share_with_team_disclaimer:
      "Note: A health record shared with a healthcare team will be accessible by all of its members.",
    other_share_methods: "Other sharing methods (QrCode, link)",
    other_transfer_methods: "Other transfer methods (QrCode, link)",
    generate_pdf: "Print QrCode",
  },
  pdfView: {
    shareHealthRecord: "This notebook is shared with you by %{user}",
    qrCode: "This QRCode is valid until %{date}",
    vaccinesList: "List of the patient's vaccines to date (%{date})",
  },
  account: {
    email: "Email",
    password: "Password",
    mobile: "Mobile phone number",
    infos:
      "Your email address and password are your login details. The mobile phone number allows a 2-factor authentication, guaranteeing the security of your account. No commercial use will be made of your personal data",
    vaccination_reminders: "Send me vaccination reminders by email",
    vaccination_reminders_infos:
      "Check this box if you would like to be notified of overdue vaccinations or vaccinations due within the next 30 days.",
  },
  datamatrix: {
    dialogTitle: "Scan a datamatrix code",
    preventionMethod: "Vaccine : ",
    serialNumber: "Serial number : ",
    batchNumber: "Lot number : ",
    expirationDate: "Expiration date : ",
    scanCta: "Scan Datamatrix code",
    loading: "Query servers",
    noDeviceError: "No camera detected on your device.",
    notFoundToast: "No vaccine match found on our servers, please manually specify the vaccine name.",
    scanError: "There's been an error scanning the Datamatrix code, please try again.",
    serverError: "There's been an error trying to reach the vaccine database, please try again.",
    successToast: "The form has successfully been filled with the scan results.",
    camera: {
      tabTitle: "Camera",
      steps: {
        "1": "Put the datamatrix code in the yellow square",
        "2": "The information search is automatic",
        "3": "The popup will automatically close and pre-fill the form",
      },
    },
    barCodeScanner: {
      tabTitle: "Bar Code Scanner",
      steps: {
        "1": "Scan the code with the barcode scanner",
        "2": "The code will appear in the text field",
        "3": "By clicking on the barcode reader the popup will close automatically and pre-fill the form",
      },
    },
    inverseImage: "Inverse camera image",
  },
  healthProfessional: {
    rppsNumber: "RPPS Number",
    noRppsNumber: "No RPPS number",
  },
  team: {
    identificationNumber: "Identification number",
  },
  yup: {
    mixed: {
      default: "is invalid",
      required: "is a required field",
      oneOf: "must be one of the following values: %{values}",
      notOneOf: "must not be one of the following values: %{values}",
      defined: "is a required field",
    },
    string: {
      length: "must be exactly %{length} characters",
      min: "must be at least %{min} characters",
      max: "must be at most %{max} characters",
      matches: "is invalid and must respect the required format",
      email: "must be a valid email",
      url: "must be a valid URL",
      uuid: "must be a valid UUID",
      trim: "must be a trimmed string",
      lowercase: "must be a lowercase string",
      uppercase: "must be a upper case string",
    },
    number: {
      min: "must be greater than or equal to %{min}",
      max: "must be less than or equal to %{max}",
      lessThan: "must be less than %{less}",
      moreThan: "must be greater than %{more}",
      positive: "must be a positive number",
      negative: "must be a negative number",
      integer: "must be an integer",
    },
    date: {
      min: "field must be the %{min} or later",
      max: "field must be the %{max} or earlier",
      invalid: "is not a valid date",
    },
    boolean: {
      isValue: "field must be %{value}",
    },
    object: {
      noUnknown: "field has unspecified keys: %{unknown}",
    },
    array: {
      min: "field must have at least %{min} items",
      max: "field must have less than or equal to %{max} items",
      length: "must be have %{length} items",
    },
  },

  countries: {
    AFG: "Afghanistan",
    ALB: "Albania",
    DZA: "Algeria",
    ASM: "American Samoa",
    AND: "Andorra",
    AGO: "Angola",
    AIA: "Anguilla",
    ATA: "Antarctica",
    ATG: "Antigua and Barbuda",
    ARG: "Argentina",
    ARM: "Armenia",
    ABW: "Aruba",
    AUS: "Australia",
    AUT: "Austria",
    AZE: "Azerbaijan",
    BHS: "Bahamas (the)",
    BHR: "Bahrain",
    BGD: "Bangladesh",
    BRB: "Barbados",
    BLR: "Belarus",
    BEL: "Belgium",
    BLZ: "Belize",
    BEN: "Benin",
    BMU: "Bermuda",
    BTN: "Bhutan",
    BOL: "Bolivia (Plurinational State of)",
    BES: "Bonaire, Sint Eustatius and Saba",
    BIH: "Bosnia and Herzegovina",
    BWA: "Botswana",
    BVT: "Bouvet Island",
    BRA: "Brazil",
    IOT: "British Indian Ocean Territory (the)",
    BRN: "Brunei Darussalam",
    BGR: "Bulgaria",
    BFA: "Burkina Faso",
    BDI: "Burundi",
    CPV: "Cabo Verde",
    KHM: "Cambodia",
    CMR: "Cameroon",
    CAN: "Canada",
    CYM: "Cayman Islands (the)",
    CAF: "Central African Republic (the)",
    TCD: "Chad",
    CHL: "Chile",
    CHN: "China",
    CXR: "Christmas Island",
    CCK: "Cocos (Keeling) Islands (the)",
    COL: "Colombia",
    COM: "Comoros (the)",
    COD: "Congo (the Democratic Republic of the)",
    COG: "Congo (the)",
    COK: "Cook Islands (the)",
    CRI: "Costa Rica",
    HRV: "Croatia",
    CUB: "Cuba",
    CUW: "Curaçao",
    CYP: "Cyprus",
    CZE: "Czechia",
    CIV: "Côte d'Ivoire",
    DNK: "Denmark",
    DJI: "Djibouti",
    DMA: "Dominica",
    DOM: "Dominican Republic (the)",
    ECU: "Ecuador",
    EGY: "Egypt",
    SLV: "El Salvador",
    GNQ: "Equatorial Guinea",
    ERI: "Eritrea",
    EST: "Estonia",
    SWZ: "Eswatini",
    ETH: "Ethiopia",
    FLK: "Falkland Islands (the) [Malvinas]",
    FRO: "Faroe Islands (the)",
    FJI: "Fiji",
    FIN: "Finland",
    FRA: "France",
    GUF: "French Guiana",
    PYF: "French Polynesia",
    ATF: "French Southern Territories (the)",
    GAB: "Gabon",
    GMB: "Gambia (the)",
    GEO: "Georgia",
    DEU: "Germany",
    GHA: "Ghana",
    GIB: "Gibraltar",
    GRC: "Greece",
    GRL: "Greenland",
    GRD: "Grenada",
    GLP: "Guadeloupe",
    GUM: "Guam",
    GTM: "Guatemala",
    GGY: "Guernsey",
    GIN: "Guinea",
    GNB: "Guinea-Bissau",
    GUY: "Guyana",
    HTI: "Haiti",
    HMD: "Heard Island and McDonald Islands",
    VAT: "Holy See (the)",
    HND: "Honduras",
    HKG: "Hong Kong",
    HUN: "Hungary",
    ISL: "Iceland",
    IND: "India",
    IDN: "Indonesia",
    IRN: "Iran (Islamic Republic of)",
    IRQ: "Iraq",
    IRL: "Ireland",
    IMN: "Isle of Man",
    ISR: "Israel",
    ITA: "Italy",
    JAM: "Jamaica",
    JPN: "Japan",
    JEY: "Jersey",
    JOR: "Jordan",
    KAZ: "Kazakhstan",
    KEN: "Kenya",
    KIR: "Kiribati",
    PRK: "Korea (the Democratic People's Republic of)",
    KOR: "Korea (the Republic of)",
    KWT: "Kuwait",
    KGZ: "Kyrgyzstan",
    LAO: "Lao People's Democratic Republic (the)",
    LVA: "Latvia",
    LBN: "Lebanon",
    LSO: "Lesotho",
    LBR: "Liberia",
    LBY: "Libya",
    LIE: "Liechtenstein",
    LTU: "Lithuania",
    LUX: "Luxembourg",
    MAC: "Macao",
    MDG: "Madagascar",
    MWI: "Malawi",
    MYS: "Malaysia",
    MDV: "Maldives",
    MLI: "Mali",
    MLT: "Malta",
    MHL: "Marshall Islands (the)",
    MTQ: "Martinique",
    MRT: "Mauritania",
    MUS: "Mauritius",
    MYT: "Mayotte",
    MEX: "Mexico",
    FSM: "Micronesia (Federated States of)",
    MDA: "Moldova (the Republic of)",
    MCO: "Monaco",
    MNG: "Mongolia",
    MNE: "Montenegro",
    MSR: "Montserrat",
    MAR: "Morocco",
    MOZ: "Mozambique",
    MMR: "Myanmar",
    NAM: "Namibia",
    NRU: "Nauru",
    NPL: "Nepal",
    NLD: "Netherlands (the)",
    NCL: "New Caledonia",
    NZL: "New Zealand",
    NIC: "Nicaragua",
    NER: "Niger (the)",
    NGA: "Nigeria",
    NIU: "Niue",
    NFK: "Norfolk Island",
    MNP: "Northern Mariana Islands (the)",
    NOR: "Norway",
    OMN: "Oman",
    PAK: "Pakistan",
    PLW: "Palau",
    PSE: "Palestine, State of",
    PAN: "Panama",
    PNG: "Papua New Guinea",
    PRY: "Paraguay",
    PER: "Peru",
    PHL: "Philippines (the)",
    PCN: "Pitcairn",
    POL: "Poland",
    PRT: "Portugal",
    PRI: "Puerto Rico",
    QAT: "Qatar",
    MKD: "Republic of North Macedonia",
    ROU: "Romania",
    RUS: "Russian Federation (the)",
    RWA: "Rwanda",
    REU: "Réunion",
    BLM: "Saint Barthélemy",
    SHN: "Saint Helena, Ascension and Tristan da Cunha",
    KNA: "Saint Kitts and Nevis",
    LCA: "Saint Lucia",
    MAF: "Saint Martin (French part)",
    SPM: "Saint Pierre and Miquelon",
    VCT: "Saint Vincent and the Grenadines",
    WSM: "Samoa",
    SMR: "San Marino",
    STP: "Sao Tome and Principe",
    SAU: "Saudi Arabia",
    SEN: "Senegal",
    SRB: "Serbia",
    SYC: "Seychelles",
    SLE: "Sierra Leone",
    SGP: "Singapore",
    SXM: "Sint Maarten (Dutch part)",
    SVK: "Slovakia",
    SVN: "Slovenia",
    SLB: "Solomon Islands",
    SOM: "Somalia",
    ZAF: "South Africa",
    SGS: "South Georgia and the South Sandwich Islands",
    SSD: "South Sudan",
    ESP: "Spain",
    LKA: "Sri Lanka",
    SDN: "Sudan (the)",
    SUR: "Suriname",
    SJM: "Svalbard and Jan Mayen",
    SWE: "Sweden",
    CHE: "Switzerland",
    SYR: "Syrian Arab Republic",
    TWN: "Taiwan (Province of China)",
    TJK: "Tajikistan",
    TZA: "Tanzania, United Republic of",
    THA: "Thailand",
    TLS: "Timor-Leste",
    TGO: "Togo",
    TKL: "Tokelau",
    TON: "Tonga",
    TTO: "Trinidad and Tobago",
    TUN: "Tunisia",
    TUR: "Turkey",
    TKM: "Turkmenistan",
    TCA: "Turks and Caicos Islands (the)",
    TUV: "Tuvalu",
    UGA: "Uganda",
    UKR: "Ukraine",
    ARE: "United Arab Emirates (the)",
    GBR: "United Kingdom of Great Britain and Northern Ireland (the)",
    UMI: "United States Minor Outlying Islands (the)",
    USA: "United States of America (the)",
    URY: "Uruguay",
    UZB: "Uzbekistan",
    VUT: "Vanuatu",
    VEN: "Venezuela (Bolivarian Republic of)",
    VNM: "Vietnam",
    VGB: "Virgin Islands (British)",
    VIR: "Virgin Islands (U.S.)",
    WLF: "Wallis and Futuna",
    ESH: "Western Sahara",
    YEM: "Yemen",
    ZMB: "Zambia",
    ZWE: "Zimbabwe",
    ALA: "Åland Islands",
  },
};
