import TaskAltOutlinedIcon from "@mui/icons-material/TaskAltOutlined";
import { Alert, Box, Chip, Grid2, Link, Tooltip, Typography } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { HealthRecord } from "@syadem/kairos-citizen-js";
import { ProfileAction, ProfileConditionsText, ProfileState } from "@syadem/sad-ui";
import { Dispatch, useEffect, useState } from "react";
import { Link as RouterLink, useLocation, useNavigate, useParams } from "react-router-dom";
import { useArianeApi } from "../../../hooks/useArianeApi";
import { useDaphne } from "../../../hooks/useDaphne";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
import { useKairosApi } from "../../../hooks/useKairosApi";
import { theme } from "../../../layout/Theme";
import { I18nInterface } from "../../../providers/I18nProvider";

const ProfileConditions = ({ state, dispatch }: { state: ProfileState; dispatch: Dispatch<ProfileAction> }) => {
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const { t, locale } = useI18n();
  const api = useKairosApi();
  const arianeApi = useArianeApi();
  const daphne = useDaphne();
  const isValidated = !!healthRecord?.validatorIds.length;
  const [certificators, setCertificators] = useState<(Professional | Team)[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (healthRecord) {
        let actCertificators: (Professional | Team)[] = [];

        if (healthRecord.validatorIds.length > 0) {
          try {
            actCertificators = await arianeApi.professionals.searchByIds([...new Set(healthRecord.validatorIds)]);
          } catch (_error) {
            actCertificators = [];
          }
        }

        setCertificators(actCertificators);
      }
    })();
  }, [arianeApi?.professionals, healthRecord]);

  const showProfileValidatedInfoMsg = (isValidated: boolean) => {
    if (isValidated) {
      return (
        <Alert severity="info" sx={{ marginTop: "20px" }}>
          {t("profile.validated_info_msg")}
        </Alert>
      );
    } else {
      return healthRecord?.sharedWithTeams.length === 0 && healthRecord?.sharedWithPro.length === 0 ? (
        <Alert severity="warning" sx={{ marginTop: "20px" }}>
          <Link to={`/health-records/${healthRecord?.id}/shares`} color="inherit" component={RouterLink}>
            {t("profile.not_validated_info_msg")}
          </Link>
        </Alert>
      ) : null;
    }
  };

  const navigate = useNavigate();
  const { pathname } = useLocation();

  return (
    <>
      {healthRecord && t && api && daphne && (
        <>
          <Grid2 container justifyContent="space-between">
            <Grid2 size={12} pb={2}>
              <Box
                sx={{
                  display: "flex",
                  width: {
                    xs: "85vw",
                    md: "95%",
                  },
                }}
              >
                <Typography
                  fontWeight="bold"
                  sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                >
                  {t("profile.conditionsTitle")}
                </Typography>
                {healthRecord.validatorIds.length > 0 && certificators ? (
                  <Tooltip title={profileCertificationDescription(certificators, healthRecord, t)}>
                    <Chip
                      label={t("profile.validated")}
                      color="success"
                      size="small"
                      sx={{ fontWeight: "500", ml: 1, border: `1px solid ${theme.palette.success[200]}` }}
                      icon={<TaskAltOutlinedIcon fontSize="small" />}
                    />
                  </Tooltip>
                ) : null}
              </Box>
            </Grid2>
          </Grid2>
          <ProfileConditionsText
            profileConditions={state.conditionsProfile}
            daphne={daphne}
            state={state}
            dispatch={dispatch}
            locale={locale}
            unsetCondition={(conditionId) => api.healthProfile.unsetCondition(id, { conditionId: conditionId })}
            withNestedProfile
            onClickCondition={(conditionId: string) =>
              navigate(`${pathname.endsWith("/") ? pathname.slice(0, -1) : pathname}#${conditionId}`)
            }
            disabled={isValidated}
          />
          {healthRecord ? showProfileValidatedInfoMsg(isValidated) : null}
        </>
      )}
    </>
  );
};

function profileCertificationDescription(
  certificators: (Professional | Team)[],
  healthRecord: HealthRecord,
  t: I18nInterface["t"],
): string {
  const certificator = certificators.find((c) => c.id === healthRecord.validatorIds.at(-1));

  if (certificator) {
    const professional = certificator as Professional;
    return t("profile.validatedBy", { certificator: `${professional.first_name} ${professional.last_name}` });
  } else {
    return t("profile.validatedByAPro");
  }
}

export default ProfileConditions;
